/* --------------------------------------------------------
 _calendar.scss 
 --------------------------------------------------------*/

.calendar-container {
	width: 100%;
	background-color: $lighter-background-color;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
	display: flex;
	flex-wrap: wrap;

	@include breakpoint(small only) {
		margin-left: -1rem;
		margin-right: -1rem;
		width: auto; 
	}

	.calendar-view {
		width: 100%;
		background-color: $white;

		@include breakpoint(large up) {
			width: 50%;	
		}

		.topbar {
			width: 100%;
			border-bottom: 1px solid #F4F2EF;
			display: flex;
			padding: 20px 0;
			flex-wrap: wrap;

			.month-picker, .location-picker {
				width: 100%;
				display: block;
				height: 42px;
				position: relative;
				padding: 0 1.5rem;

				.location {
					display: inline-block;
					padding: 2px;
					border: 1px solid #F4F2EF;
					background: #fbfbfa;
					user-select: none;
					width: 48%;
					text-align: center;
					margin-top: 28px;
					font-weight: bold;
					color: rgba(0,0,0,0.5);
					transition: all 200ms $transition;

					&:hover {
						cursor: pointer;
						box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
					}

					&.gold {
						background: linear-gradient(158deg, #F5E992 0%, #D1A22E 100%);
						color: #FFF;
						text-shadow: 0 1px 2px rgba(197,150,35,0.63);
						border: none;
						padding: 3px;
					}
				}

				@include breakpoint(medium up) {
					width: 50%;	
				}

				@include breakpoint(xlarge up) {
					height: 56px;
					padding: 0 2rem;
				}

				.label {
					display: block;
					width: 100%;
					font-weight: $global-weight-bold;
					opacity: 0.4;
					text-transform: uppercase;
					font-size: rem-calc(11px);
					letter-spacing: 1.15;
					pointer-events: none;
					position: absolute;
					top: 6px;
					margin: 0;

					@include breakpoint(xlarge up) {
						font-size: rem-calc(12px);
						top: 8px;
					}
				}

				.month-title, select {
					font-size: rem-calc(16px);
					font-weight: 300;
					height: 42px;
					width: 100%;

					@include breakpoint(xlarge up) {
						height: 56px;
						font-size: rem-calc(20px);
					}
				}

				.month-title {
					padding-top: 1.25rem;
					display: block;
					user-select: none;

					@include breakpoint(xlarge up) {
						padding-top: 1.45rem;
					}
				}

				.location-label {
					text-indent: 1px;
				}

				select {
					border: none;
					background-color: $white;
					box-shadow: none;
					outline: none;
					-webkit-appearance: none;
    				-moz-appearance: none;
    				text-overflow: '';
    				text-indent: 1px;
    				padding-top:20px;
    				cursor: pointer;
				}

				.icon {
					position: absolute;
					top: 50%;
					right: 2rem;
					margin-top: -10px;
					pointer-events: none;
					user-select: none;
				}

				.month-controls {
					position: absolute;
					height: 42px;
					top:1px;
					right: 1.25rem;
					display: flex;
					width: 4.5rem;

					@include breakpoint(xlarge up) {
						height: 56px;
						top: 0;
					}

					div {
						width: 50%;
						display: flex;
						height: 100%;
						align-items: center;
						justify-content: center;
						cursor: pointer;

						&:first-child {
							svg {
								transform: rotate(90deg);
							}
						}

						&:last-child {
							svg {
								transform: rotate(-90deg);
							}
						}
					}
				}
			}

			.location-picker {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.month-picker {
				
				@include breakpoint(small only) {
					margin-bottom: 2rem;
					&:after {
					 	content:"";
					 	background: #F4F2EF;
					 	width: 100%;
					 	height: 1px;
					 	position: absolute;
					 	left:0;
					 	bottom: -1rem;
					}
				}

				@include breakpoint(medium up) {

					&:after {
					 	content:"";
					 	background-image: url(/dist/images/border.svg);
					 	position: absolute;
					 	background-position: bottom left;
					 	height: 100%;
						width: 1px;
						left: auto;
						right:0;
						bottom: auto;
						top: 0;
					}
				}
			}

		}

		.calendar-grid {

			padding: 1.5rem;

			@include breakpoint(small only) {
				padding:1.5rem 0 0 0;
				margin-left: -1px;
				margin-right:-1px;
				margin-bottom: -1px;
			}

			.days-of-the-week {
				display: flex;
				align-items: center;
				font-size: rem-calc(10px);
				text-transform: uppercase;
				justify-content: space-between;
				font-weight: $global-weight-bold;
				opacity: 0.4;
				margin-bottom: 1rem;

				.header-day {
					width: 14.2857142857%;
					flex-shrink: 0;
					text-align: center;
				}
			}

			.days {
				display: flex;
				justify-items: start;
				flex-wrap: wrap;
				font-size: rem-calc(12px);
				font-weight: $global-weight-medium;
				margin-top:-4px;
				border-top: 1px solid #E6E6E6;
				border-right: 1px solid #E6E6E6;

				.day {
					width: 14.2857142857%;
					padding-bottom: 14.2857142857%;
					text-align: center;
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					border-left: 1px solid #E6E6E6;
					border-bottom: 1px solid #E6E6E6;
					position: relative;
					cursor: pointer;
					z-index: 1;

					span.date {
						display: block;
						position: absolute;
						align-items: center;
						top:0.6rem;
						right:0.75rem;
						text-align: right;
						font-size: rem-calc(12px);
						color: rgba($black,0.7);
						z-index: 2;

						@include breakpoint(small only) {
							font-size: rem-calc(11px);
							width: 100%;
							top:8px;
							left:0;
							text-align: center;
						}
					}


					&:after {
						content:"";
						width: calc(100% + 2px);
						height: calc(100% + 2px);
						z-index: 0;
						background-image: linear-gradient(-90deg, #D1A22E 0%, #F1E187 30%, #F1E187 73%, #D1A22E 100%);
						position: absolute;
						top: -1px;
						left: -1px;
						opacity: 0;
					}

					span.day-content {
						position: absolute;
						top:2px;
						left:2px;
						width: calc(100% - 4px);
						height: calc(100% - 4px);
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 1;
						background-color: $white;

						span.status {
							
							span.title {
								width: 100%;
								display: block;
								font-weight: $global-weight-bold;
								font-size: rem-calc(14px);

								@include breakpoint(large only) {
									font-size: rem-calc(13px);
									margin-bottom: -2px;
									margin-top: 8px;
								}

								@include breakpoint(small only) {
									font-size: rem-calc(10px);
									padding-top:10px;
									line-height: 1.2;
									padding-top: 15px;
    								line-height: 1;
								}
							}

							span.subtitle {
								width: 100%;
								display: block;
								font-size: rem-calc(12px);
								opacity: 0.6;
								
								&.hide-mobile {
									@include breakpoint(small only) {
										display: none;
									}
								}

								 @include breakpoint(large only) {
									font-size: rem-calc(11px);
								}
							}

						}	
					}

					&.adjacent-month {
						background-image: none;
						//cursor: default;
						
						span.date {
							color: rgba($black,0.3);
						}

					}


					&.inactive {
						background: url('/dist/images/stripes.png') 0 0 repeat;
						background-size: 12px 12px;
						cursor: default;

						span.day-content {
							display: none;
						}

						span.date {
							color: rgba($black,0.35);
						}

					}

					&.selected:not(.inactive) {
						span.day-content {
							display: flex;
						}
					}

					&.today {
						span.date {
							color: #EF674E;
							font-weight: $global-weight-bold;
						}
					}

					&:hover:not(.inactive), &.selected {
						z-index: 2;
						&:after {
							opacity: 1;
						}

						span.date {
							color: #D2A431;
							font-weight: $global-weight-bold;
						}

						span.day-content {
							display: flex;
						}
					}
				}
			}
		}
	}

	.calendar-content {
		width: 100%;
		border-top: 1px solid $lighter-border-color;
		padding: 1.5rem;
		padding-top: 0;
		position: relative;

		@include breakpoint(large up) {
			width: 50%;
			border-left: 1px solid $lighter-border-color;
			border-top: none;
		}

		.calendar-content-header {
			display: flex;
			font-family: $heading-font-family;
			font-weight: $global-weight-regular;
			margin: 0 -1.5rem;
			margin-bottom: 2rem;
			align-items: center;
			flex-wrap: wrap;
			position: sticky;
			top: -1px;
			z-index: 100;
			background: #FBFBFA;
			border-bottom: 1px solid #F1EEEB;
			padding: 1rem 1.5rem;
			height: 97px;

			@include breakpoint(xlarge up) {
				margin-bottom: 2.5rem;
			}

			@include breakpoint(small down) {
				height: auto;
			}

			> div {
				
				&.header-title {
					width: auto;
					flex-grow: 1;

					.title {
						display: block;
						font-size: rem-calc(22px);

						@include breakpoint(small down) {
							font-size: rem-calc(18px);
						}
					}

					.subtitle {
						display: block;
						font-size: rem-calc(16px);
						opacity: 0.7;

						@include breakpoint(small down) {
							font-size: rem-calc(14px);
						}
					}
				}

				&.header-info {
					flex-grow: 0;
					display: flex;
					

					@include breakpoint(medium up) {
						justify-content: flex-end;
					}

					.header-info-item {
						padding-right: 2.5rem;
						display: flex;
						align-items: center;

						&:last-of-type {
							padding-right: 0;
						}

						@include breakpoint(medium up) {
							padding-right: 0;
							margin:0;
							padding-left: 2.5rem;
						}

						@include breakpoint(small down) {
							padding-right: 1rem;
						}

						.icon {
							width: 28px;
							height: 28px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 0.5rem;
						}

						.info-title {
							font-weight: $global-weight-medium;
							margin-bottom: -2px;
						}

						.info-subtitle {
							opacity: 0.7;
						}
					}
				}
			}
		}


		.calendar-shows {

			padding: 0;

			.performance-line {
				padding: 0.75rem;
				border-bottom: 1px solid $lighter-border-color;

				&:after { display: none; }

				.image {
					margin-right: 1rem;

					@include breakpoint(small only) {
						display: none;
					}
				}

				h2 {
					font-size: rem-calc(18px);
					@include breakpoint(small only) {
						margin-left: 0rem;
						font-size: rem-calc(16px);
						padding-left: 0.25rem;
					}
				}

				h3 {
					font-size: rem-calc(16px);
					@include breakpoint(small only) {
						margin-left: 0rem;
						font-size: rem-calc(14px);
						padding-left: 0.25rem;
					}
				}

				.seats {
					@include breakpoint(small only) {
						display: none;
					}

					@include breakpoint(large only) {
						display: none;
					}

					img {
						position: relative;
					}
				}

				.button-wrapper {
					margin-left: 1.5rem;
					margin-right: 0.75rem;

					@include breakpoint(small only) {
						margin: 0;
					}
				}
			}
		}

		.search-button {
			padding: 0.5rem 0;
			margin-top: 1.5rem;
		}
	}
}

.calendar-promotions {
	overflow: hidden;
	margin: 1.5rem;

	@include breakpoint(large down) {
		margin-top: 2rem;
	}

	@include breakpoint(small only) {
		margin-top: 1.5rem;
	}

	.promo {
		margin-bottom: 1rem;
		background: #000;
		color: $white;
		background-color: $header-background-color;
		position: relative;
		overflow: hidden;
		min-height: 183px;

		a {
			color: $white;
		}

		.content {
			position: absolute;
			top: 8px; right: 8px; bottom: 8px; left: 8px;
			display: flex;
			align-items: center;
			border: 1px solid #F6CC52;
			padding-left: 56px;
			padding-right: 1.5rem;
		}

		

		@include breakpoint(large down) {
			width: 100%;
			margin-bottom: 0.75rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		@include breakpoint(small only) {
			margin-bottom: 1px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: url(/dist/images/bg-dots.svg);
			filter: blur(6px);
			opacity: 0.25;
			z-index: 0;
			width: 100%;
			height: 100%;
		}
			
		h5 {
			color: #FFF;
			font-family: $body-font-family;
			font-weight: $global-weight-bold;
			margin-bottom: 0px;
			padding:0;
			position: relative;
			z-index: 1;
			font-size: 1rem;
		}

		p {
			color: inherit;
			position: relative;
			z-index: 1;
			font-size: 1rem;
			line-height: 1.7;
			margin: 0;

			@include breakpoint(small only) {
				font-size: rem-calc(14px);
				font-weight: $global-weight-regular;
				opacity: 0.75;
				line-height: 1.6;
			}
		}
	}

}

#clndr-show {
	.day.selected {
		.day-content {
			&:after {
				content:"Valgt dag";
				font-weight: $global-weight-bold;

				@include breakpoint(small only) {
					content:"Valgt";
					font-size: 10px;
					margin-top: 8px;
				}
			}

			
		}
	}
}

.calendar-container {

	form {
		width: auto;
		margin-left: -0.5rem;
		margin-right: -0.8rem;
	}

	.freeform-row {
		margin: 0;

		@include breakpoint(small only) {
			flex-wrap: wrap;
		}
	}
	.freeform-row .freeform-column {
		margin: 0;
		padding: 8px;

		@include breakpoint(small only) {
			width: 100%;
			flex: 1 1 100%;
		}

		.freeform-label:not(.freeform-input-only-label) {
			display: none;
		}

		.freeform-input {
			padding: 14px 15px 15px 15px;
			border: 1px solid #E6E6E3;
			box-shadow: none;
			outline: none;
			font-size: rem-calc(14px);
			font-family: $body-font-family;

			@include breakpoint(xlarge up) {
				font-size: 1rem;
			}

			&::placeholder {
				color: rgba($black, 0.45);
			}

			&:focus {
				border-color: $color-latter;
			}
		}

		textarea {

			@include breakpoint(xlarge up) {
				min-height: 150px;
			}
		}

		.freeform-instructions {
			font-weight: $global-weight-bold;
			opacity: 1;
			color: $black;
			display: none;
			resize: vertical;
		}

		.freeform-input-only-label {
			font-weight: $global-weight-light;
			font-size: 1rem;
			margin-top: 10px;
		}

		button {
			font-family: $heading-font-family;
			border-radius: 1000px;
			font-weight: $global-weight-bold;
			height: 48px;
			border: 1px solid #D1A533;
			line-height: 46px;
			padding: 0 24px;
			cursor: pointer;
			margin-bottom: 9rem;
		}
	}

	.ff-form-errors, .ff-form-success {
		margin-bottom: 2rem;
		width: calc(100% - 1rem);
		margin-left: 0.5rem;
		padding: 15px;
		border: 1px solid #f5c6cb;
		background: #f8d7da;
		border-radius: 0px;
		color: #721c24;
		font-size: rem-calc(14px);
		font-weight: $global-weight-regular;
	}

	.ff-form-success {
		background-color: #72C773 !important;
		border: none !important;
	}

	ul.ff-errors {
		display: none;
	}
}

.booking-help {
	width: calc(100% - 4rem);
	background-color: #2D0933;
	padding: 1rem 1.25rem;
	color: $white;
	display: flex;
	font-family: $heading-font-family;
	font-weight: $global-weight-regular;
	justify-content: space-between;
	position: absolute;
	bottom: 2rem;
	left: 2rem;

	@include breakpoint(small only) {
		width: calc(100% - 3rem);
		left: 1.5rem;
		bottom: 1.5rem;
		flex-wrap: wrap;		
	}

	a {
		color: inherit;
	}

	.heading {
		font-weight: $global-weight-bold;
		color: #F4E68E;

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	.content {
		span {
			margin-left: 10px;

			@include breakpoint(small only) {
				width: 100%;
				display: block;
				margin: 2px 0 0 0;
			}
		}
	}
}

.calendar-promotions {

	.slick-dots {
		position: absolute;
		bottom: 34px;
		right: 24px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		list-style: none;
		padding: 0;

		@include breakpoint(medium down) {
			bottom: 24px;
		}

		li {
			margin: 0;
			margin-right: 7px;

			&:last-of-type {
				margin-right: 0;
			}

			&:only-child {
				opacity: 0;
			}

			button {
				display: inline-block;
				width: 14px;
				height: 14px;
				border-radius: 100%;
				color: transparent;
				background: rgba(white, 0.3);
			}

			&.slick-active {
				button {
					background: #fff;
				}
			}
		}
	}
}


