/* --------------------------------------------------------
 _app.scss - copy/paste
 --------------------------------------------------------*/

#content {
	max-width: $content-max-width;
}

.bg-light, .bg-darker {
	overflow: hidden;

	&.no-overflow {
		overflow: initial;
	}
}

.section-container {
	margin-top: 6rem;
	margin-bottom: 1rem;
	width: 100%;

	&:first-of-type {
		margin-top:0;
	}
}

footer {
	border-top: 1px solid $lighter-border-color;
	margin-top: -1px;
}

.grid-container:not(.full)>.grid-padding-x.grid-padding-x-small {
	margin-right: -0.75rem;
	margin-left: -0.75rem;

    > .cell {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}
}

.grid-container {

	@include breakpoint(medium up) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	@include breakpoint(large up) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	@include breakpoint(xxlarge up) {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	@media screen and (min-width: 1440px) {
		padding-left: 4rem;
		padding-right: 4rem;
	}

	@media screen and (min-width: 1690px){

	}
}

.slick-list {

	overflow: visible;

	.card, .item > a {
		cursor: move !important; /* fallback if grab cursor is unsupported */
	    cursor: grab !important;
	    cursor: -moz-grab !important;
	    cursor: -webkit-grab !important;

	    &:focus {
	    	cursor: grabbing !important;
		    cursor: -moz-grabbing !important;
		    cursor: -webkit-grabbing !important;
	    }

	    .button-wrapper {
			cursor: pointer !important;
	    }
	}
}

@import 'typography';
@import 'header';
@import 'accordion';
@import 'cards';
@import 'calendar';
@import 'image-gallery';
@import 'footer';
