/* --------------------------------------------------------
 _typography.scss 
 --------------------------------------------------------*/

body,html {
	font-family: $body-font-family;
	font-weight: $global-weight-light;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #000 !important;
	margin: 0; padding: 0;
	height: 100%;
}

body {
	background-color: #fcfdfc;

	&.no-scroll {
		overflow: hidden;
	}
}

.align-center {
		
	text-align: center;

	* {
		text-align: center;
	}
}

/* default headlines */
h1,h2,h3,h4,h5,h6 {
	font-family: $heading-font-family;
	font-weight: $global-weight-medium;
	margin-top:0;

	&.light {
		font-weight: $global-weight-medium;
		font-family: $heading-font-family !important;
		margin-bottom:0;
	}
}

h1 {
	
	font-size: rem-calc(28px);

	@include breakpoint(medium up) {
		font-size: rem-calc(52px);
	}
}

h2 {
	
	font-size: rem-calc(24px);

	@include breakpoint(medium up) {
		font-size: rem-calc(28px);
	}

	@include breakpoint(xlarge up) {
		font-size: rem-calc(32px);
	}
}

h3 {
	
	font-size: rem-calc(20px);
	margin-bottom: 0.5rem;

	@include breakpoint(medium up) {
		font-size: rem-calc(26px);
	}
}

h4 {
	
	font-size: rem-calc(18px);

	@include breakpoint(medium up) {
		font-size: rem-calc(22px);
	}
}

h5 {
	
	font-size: rem-calc(16px);
	margin-bottom: 1.5rem;

	@include breakpoint(medium up) {
		font-size: rem-calc(18px);
	}
}


/* heading rows */
.heading-row {
	font-family: $heading-font-family;
	font-weight: $global-weight-medium;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 1.5rem;

	@include breakpoint(medium up) {
		margin-bottom: 3rem;
	}

	.flex-1 {
		flex: 1 0 auto;
		background-image: url(/dist/images/border.svg);
		background-repeat: repeat-x;
		background-position: center -1px;
		height: 5px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: $heading-font-family;
		font-weight: $global-weight-medium;
		margin: 0;
		padding-right: 7px;
		font-size: rem-calc(14px);
		letter-spacing: 1.84px;
		text-transform: uppercase;

		@include breakpoint(medium up) {
			font-size: 1rem;
			padding-right: 10px;
		}
	}
}

/* label headlines */
.label {
	h1,h2,h3,h4,h5 {
		font-family: $body-font-family;
		font-weight: $global-weight-light;
	}
}

/* all caps heading */
.allcaps-heading {
	font-family: $heading-font-family;
	font-weight: $global-weight-medium;
	font-size: 1rem;
	letter-spacing: 1.84px;
	text-transform: uppercase;
	margin: 0 0 1rem 0;

	@include breakpoint(small only) {
		font-size: rem-calc(14px);
		letter-spacing: 1.5px;
	}
}

/* large phone numbers */
a.large-tel {
	font-size: 22px;
	line-height: 30px;
	font-weight: $global-weight-regular;
	text-decoration: none;
}

/* paragraphs */
p {
	font-family: $body-font-family;
	font-weight: $global-weight-light;
	line-height: 1.9;
	margin: 0 0 1rem 0;
	padding: 0;

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			color: lighten($color-latter, 15%);
		}
	}

	&.ingress {
		font-size: rem-calc(28px);
		margin-bottom: 2.75rem;
		line-height: 1.7;

		@include breakpoint(small only) {
			font-size: 20px;
			line-height: 33px;
		}
	}
}

/* no margin bottom for the last paragraph */
p:only-of-type, p:last-of-type {
	margin-bottom: 0;
	padding-bottom: 0;
}

/* article styling */
.matrix, article {

	p, ul, ol {
		font-size: rem-calc(20px);
		line-height: 1.8;
		margin-bottom: 1.75rem;

		@include breakpoint(small only) {
			font-size: rem-calc(16px);
		}
	}

	.cell > p {
		&:only-of-type, &:last-of-type {
			margin-bottom: -8px;
		}
	}

	.card {

		p:only-of-type, p:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&:last-of-type {
			margin-bottom:0;
		}
	}

	.article-image-large {
		width: 100%;
		margin-top: 2.75rem;

		img {
			width: 100%;
		}
	}

	p strong {
		font-weight: $global-weight-bold;
	}
}

ul, li, ol li {
	margin-bottom: 0.75rem;

	@include breakpoint(small only) {
		margin-bottom: 0.5rem;
	}

	&:last-child {
		margin-bottom:0;
	}
}

.bg-light, .bg-lighter {
	.matrix {
		&:last-of-type {
			padding-bottom: 1rem;
		}
	}
}

.content-subnav {
	width: 100%;
	background-color: rgba(255,255,255,0.5);
	box-shadow: 0 1px 0 0 rgba(0,0,0,0.05);
	padding: 3rem;
	margin-top: 2.75rem;

	@include breakpoint(large up) {
		width: calc(100% - 2rem);
	}

	.allcaps-heading {
		opacity: 0.6;
		margin-bottom: 0.75rem;
	}

	ul {
		list-style: none;
		margin:0;
		padding:0;
		margin-bottom: 2rem;

		li {
			width: 100%;
			margin:0;
			padding:0;

			a {
				color: $black;
				font-weight: $global-weight-light;
				padding: 0.25rem 0;
				display: block;
				width: 100%;
				position: relative;

				&:hover {
					color: lighten($color-latter, 15%);
					cursor: pointer;
				}
			}

			&.active a {
				font-weight: $global-weight-bold;

				&:after {
					content:"";
					width: 3px;
					height: 28px;
					position: absolute;
					left: -3rem;
					top: 1px;
					background-image: linear-gradient(0deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
				}
			}
		}

		&:last-of-type {
			margin-bottom:-0.25rem;
		}
	}
}

.restaurant .heading-row {
	display: block;
	
	span {
		display: none;
	}

	h2 {
		text-transform: capitalize;
		text-align: center;
		font-size: rem-calc(28px);
		margin-bottom: 0.67em;

		@include breakpoint(medium up) {
			font-size: rem-calc(52px);
		}
	}
}

.intro {
	margin-bottom: 2.5rem;

	@include breakpoint(small only) {
		margin-bottom: 1.75rem;
	}

	p {
		font-size: 28px;
		line-height: 48px;

		@include breakpoint(small only) {
			font-size: 20px;
			line-height: 33px;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}