/* --------------------------------------------------------
 _footer.scss
 --------------------------------------------------------*/

 footer {
	background: #fff;
	padding: 3rem 0;

	@include breakpoint(medium up) {
		padding: 4rem 0;
	}

	@include breakpoint(large up) {
		padding: 5rem 0;
	}

	@include breakpoint(xlarge up) {
		padding: 7rem 0;
	}

	h2 {
		font-size: 20px;
		font-weight: $global-weight-medium;
		margin-bottom: 0.25rem;
		margin-top:-3px;

		@include breakpoint(medium up) {
			font-size: 24px;
		}

		@include breakpoint(xlarge up) {
			font-size: 28px;
		}
	}

	h3, .contactInformation h3 {
		font-size: rem-calc(14px);
		letter-spacing: 1.84px;
		margin-top:-2px;

		@include breakpoint(xlarge up) {
			font-size: rem-calc(16px);
		}
	}

	.large-link {
		font-size: 22px;
		line-height: 32px;
		margin-top:-5px;
		display: inline-block;
		font-weight: $global-weight-regular;

		&:hover {
			color: lighten($color-latter, 15%);
		}
	}

	.contactInformation a {
		text-decoration: none;
	}

	.contactInformation p {
		display: block;
		font-size: 0.875rem;

		@include breakpoint(small only) {
			margin-bottom: 1.25rem;
		}

		@include breakpoint(xlarge up) {
			font-size: 1rem;
		}

		&:last-of-type {
			margin-bottom:-6px;

			@include breakpoint(small only) {
				margin-bottom: 0.25rem;
			}
		}
	}



	p {
		margin-top: 0;
		margin-bottom: 2rem;
		font-size: rem-calc(14px);

		@include breakpoint(medium up) {
			font-size: rem-calc(16px);
			max-width: 530px;
		}

		@include breakpoint(xlarge up) {
			font-size: rem-calc(18px);
		}

		&:only-of-type {
			margin-bottom: 1.5rem;

			@include breakpoint(medium up) {
				margin-bottom: 2.5rem;
			}
		}


	}

	.times {
		margin-bottom: 1.25rem;

		@include breakpoint(medium up) {
			margin-bottom: 2.5rem;
		}

		.cell {
			font-size: 0.875rem;

			@include breakpoint(xlarge up) {
				font-size: 1rem;
			}
		}
	}

	.form {
		@include breakpoint(medium only) {
			width: 100%;
			text-align: center;
		}
	}

	.newsletter {
		padding: 2px;
		display: flex;
		max-width: 556px;
		height: 48px;
		background-image: linear-gradient(-90deg, #D1A22E 0%, #F1E187 30%, #F1E187 60%, #EDD97C 66%, #D1A22E 100%);
		margin-bottom: 0.5rem;

		@include breakpoint(medium up) {
			height: 62px;
			padding: 3px;
			margin-bottom: 0;
		}

		@include breakpoint(medium only) {
			margin: 0 auto;
		}

		input[type="email"] {
			border: none;
			-webkit-appearance: none;
			padding: 12px 15px 16px 15px;
			flex: 1 0 auto;
			font-size: rem-calc(14px);
			font-weight: $global-weight-regular;

			@include breakpoint(medium up) {
				font-size: rem-calc(16px);
			}

			@include breakpoint(large up) {
				font-size: rem-calc(18px);
			}

			&:focus {
				outline: none;
			}
		}

		button {
			font-size: 16px;
			color: $white;
			letter-spacing: 0;
			text-align: center;
			text-shadow: 0 1px 1px rgba(197,150,35,0.68);
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 0 auto;
			max-width: 100px;
			user-select: none;
			--webkit-apperance: none;
			border: none;
			font-family: $heading-font-family;
			font-weight: $global-weight-bold;

			@include breakpoint(medium up) {
				font-size: 22px;
				max-width: 140px;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	.border {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			width: 1px;
			top: 0; bottom:0; right: 0;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;
		}

		@include breakpoint(small only) {
			
			padding-bottom: 2rem;
			margin-bottom: 2rem;

			&:after {
				content: '';
				position: absolute;
				height: 3px;
				top: auto;
				bottom: 1px;
				right: auto;
				left: 0;
				background-image: url(/dist/images/border.svg);
				background-position: bottom left;
				width: 100%;
			}
		}
	}

	.footer-top {

		> .grid-x {
			
			> .cell {
			
				@include breakpoint(medium up) {
					padding-left: 32px;
				}

				@include breakpoint(large up) {
					padding-left: 48px;
				}

				@include breakpoint(xlarge up) {
					padding-left: 95px;
				}

				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					@include breakpoint(large up) {
						display: flex;
						align-items: center;
					}

					@include breakpoint(medium only) {
						padding-left: 0;
						padding-top: 3rem;
						margin-top: 4rem;
						position: relative;
						text-align: center;

						h2, p {
							text-align: center;
							width: 100%;
							max-width: 100%
						}

						&:after {
							content: '';
							position: absolute;
							height: 1px;
							top: 0;
							right: 0;
							left: 0;
							background-image: url(/dist/images/border.svg);
							background-position: bottom left;
						}
					}
				}

				&:nth-child(2) {
					@include breakpoint(medium only) {
						&:after {
							display: none;
						}
					}
				}
			}
		}

		
	}

	.footer-bottom {
		margin-top: 2rem;
		position: relative;
		padding-top: 2rem;
		font-size: 14px;
		color: $black;
		font-weight: $global-weight-regular;

		@include breakpoint(medium up) {
			margin-top: 4rem;
		}

		@include breakpoint(large up) {
			margin-top: 5rem;
			padding-top: 2rem;
		}

		@include breakpoint(xlarge up) {
			margin-top: 7rem;
		}

		&:before {
			content: '';
			position: absolute;
			height: 1px;
			top: 0; right: 0; left: 0;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;
		}

		.cell {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&:last-of-type {
				padding-top: 0 !important;
			}

			&:after {
				display: none;
			}

			@include breakpoint(medium down) {
				flex-direction: column;

				> div:first-of-type {
					margin-bottom: 15px;
				}
			}

			img {
				margin-left: 6px;
			}

			span {
				opacity: 0.6;
				font-size: 14px;
				font-weight: $global-weight-regular;
				letter-spacing: 0;
				margin-right: 5px;
				position: relative;
				top: 1px;
			}
		}
	}

	.nav-item {
		color: rgba($black, 0.6);
		font-size: 14px;
		font-weight: $global-weight-regular;
		text-decoration: none;
		margin-right: 14px;
		transition: all 400ms $transition;

		@include breakpoint(small only) {
			display: none;
		}

		&:first-of-type {
			margin-left: 14px;
		}

		&:hover {
			color: lighten($color-latter, 15%);
		}
	}
}
