.show-header {
	padding-bottom: 3rem;
	color: #fff;

	@include breakpoint(medium up) {
		padding-bottom: 4rem;
	}

	@include breakpoint(large up) {
		padding-bottom: 5rem;
	}

	@include breakpoint(xlarge up) {
		padding-bottom: 7rem;
	}


	div {
		width: 100%;
	}

	.grid-container {

	}

	.left {
		display: flex;
		align-items: center;

		@include breakpoint(medium down) {
			padding-bottom: 45px;
		}

		img {
			width: 100%;

			@include breakpoint(medium only) {
				width: 75%;
				margin: 0 auto;
				display: block;
				max-width: 800px;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;

		@include breakpoint(large up) {
			padding-left: 2rem;
			padding-right: 1rem;
		}

		@include breakpoint(xlarge up) {
			padding-left: 3rem;
			padding-right: 0;
		}

		@include breakpoint(1450px up) {
			padding-left: 6rem;
		}

		@include breakpoint(medium only) {
			padding: 0 2rem;
		}

		h1, h2 {
			margin: 0;
		}

		h1 {
			margin-bottom: 1.25rem;
			font-size: 46px;

			@include breakpoint(medium down) {
				line-height: 45px;
			}

			@include breakpoint(small down) {
				font-size: 28px;
				margin-bottom: 0.75rem;
			}
		}

		h2 {
			opacity: 0.6;
			font-size: 20px;
			letter-spacing: 0;
			line-height: 23px;
			margin-bottom: 5px;

			@include breakpoint(medium down) {
				font-size: 18px;
				line-height: 20px;
			}

			@include breakpoint(medium down) {
				font-size: 16px;
				line-height: 18px;
			}
		}

		p {
			font-size: 16px;
			letter-spacing: 0;
			margin-bottom: 2.65rem;
			line-height: 1.7;
			margin-bottom: 1.75rem;

			@include breakpoint(medium up) {
				margin-bottom: 2.25rem;
			}

			@include breakpoint(large up) {
				font-size: 18px;
				line-height: 1.8;
				margin-bottom: 2.25rem;
			}

			@include breakpoint(xlarge up) {
				font-size: 20px;
				margin-bottom: 2.65rem;
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		padding: 24px 0;

		@include breakpoint(1450px down) {
			align-items: center;
			justify-content: flex-start;
			padding-bottom: 0;

			div {
				height: 50px;
				width: 50%;
				margin-bottom: 24px;
			}
		}

		@include breakpoint(medium down) {
			div {
				width: 33.33%;

				&:nth-child(2) {
					justify-content: center;
				}

				&:nth-child(3) {
					justify-content: flex-end;
				}

				&:last-of-type {
					width: 100%;
					justify-content: center;
				}
			}
		}

		@include breakpoint(small down) {
			
			flex-wrap: wrap;

			div {
				width: 50% !important;
				justify-content: flex-start !important;
			}
		}

		&:before,
		&:after {
			content: '';
			height: 1px;
			right: 0;
			left: 0;
			background-image: url(/dist/images/border-light.svg);
			background-position: bottom left;
			position: absolute;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}

		div {
			display: flex;
			align-items: center;
			flex-shrink: 1;
			flex-grow: 0;
			width: auto;
			padding-right: 2rem;

			@include breakpoint(1450px up) {
				padding-right: 2.5rem;
			}

			@include breakpoint(medium down) {
				width: 100%;
			}

			img {
				margin-right: 10px;
			}

			label {
				display: block;
				font-family: $heading-font-family;
				font-weight: $global-weight-medium;
				color: #fff;
				line-height: 1;
			}

			&:last-of-type {
				@include breakpoint(large up) {
					justify-content: flex-end;
					flex-shrink: 1;
					margin-left: auto;
					padding-right: 0;
				}
				
			}
		}
	}
}

.show-button {
	background-image: url(/dist/images/button.svg);
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	height: 44px;
	width: 131px;
	font-family: $heading-font-family;
	font-weight: $global-weight-bold;
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: 0;
	text-align: center;
	line-height: 44px;
}

.review-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #FFFFFF;
	font-size: 20px;
	color: #000000;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 12px;
	position: relative;
	padding: 25px 35px;
	position: relative;
	transition: 200ms $transition;
	transform: scale(1);

	&:hover {
		transform: scale(1.015);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
	}

	a {
		color: inherit;
	}

	a:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
	}

	img {
		width: 48px;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 3px;
		background: linear-gradient(0deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
	}

	h3 {
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		line-height: 1;
		font-weight: 300;
		margin: 0;
		margin-bottom: 3px;
	}

	b {
		font-weight: 600;
		font-size: 14px;
		line-height: 1;
	}
}

.youtube {
	margin-bottom: 45px;
	iframe {
		border: none;
	}
}

.heading-row.margin {
	margin-bottom: 45px;

	.showInfo & {
		margin-right: -60px;
	}
}

.showInfo {
	padding-right: 60px;
	padding-bottom: 60px;

	@include breakpoint(medium down) {
		padding-right: 0;
	}

	.intro {
		margin-bottom: 3rem;

		p {
			font-size: 28px;
			line-height: 48px;
		}
	}

	p {
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		line-height: 34px;
		margin-top: 0;
		margin-bottom: 35px;
	}

	h3 {
		margin-bottom: 5px;
	}
}

.infoblock {
	margin-bottom: 80px;
}

.comedian-line {
	background: #fff;
	display: flex;
	align-items: center;
	padding: 10px;
	width: 100%;
	padding: 19px 16px;
	position: relative;
	transition: 200ms $transition;
	transform: scale(1);
	margin-bottom: 12px;

	&:hover {
		transform: scale(1.015);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
	}

	a:after {
		content: '';
		position: absolute;
		top: 0; right: 0; left: 0; bottom: 0;
	}

	img {
		max-width: 56px;
		border-radius: 100%;
		margin-right: 16px;
	}

	h4 {
		margin: 0;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
	}

	span {
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
	}
}

.slick-track {
	display: flex;

	.item.slick-slide {
		height: auto;
		flex: 1 0 auto;

		.show-card {
			height: 100%;
			margin-bottom: 0;
		}
	}
}

.show-more {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64px;
	font-weight: 400;

	img {
		margin-right: 1rem;
	}
}