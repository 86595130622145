/* --------------------------------------------------------
 _image-gallery.scss 
 --------------------------------------------------------*/

 .image-gallery {
	margin-top: 2rem;
	margin-left: -15%;
	margin-right: -15%;
	margin-bottom: -4rem;

	.item {
		padding: 0 1rem;
		user-select: none;
		outline: none;
		margin: 0 !important;

		@include breakpoint(large down) {
			padding: 0 0.5rem;
		}
	}

	.slick-dots {
		
		width: auto;
		margin: 2rem auto 0 auto;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		list-style: none;
		padding:0;

		li {
			margin: 0 0.25rem;
			padding:0;
			button {
				width: 14px;
				height: 14px;
				border-radius: 1000px;
				overflow: hidden;
				background-color: rgba($black, 0.1);
				display: block;
				text-indent: -9000px;
				cursor: pointer;

				&:focus {
					outline: none;
				}
			}

			&.slick-active {
				button {
					background-image: linear-gradient(-142deg, #F5E992 0%, #D1A22E 100%);
				}
			}
		}
	}

 }