@charset 'utf-8';
@import "global";
@import "settings";
@import "util/util";
@import "foundation";
@import "fonts";
@import "nav";
@import "frontpage";
@import "matrix";
@import "datepicker";
@import "slick";
@import "shows";
@import "forms";
@import "search";

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-visibility-classes;

*,
*:before,
*:after {
	box-sizing: border-box;
}

html.open body {
	overflow: hidden;
}

.hidden {
	display: none !important;
}

main {
	position: relative;
}

#content {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	background-color: $color-background-dark;
	background-image: url(/dist/images/header.jpg);
	background-position: top center;
	background-size: contain;
	overflow-x: hidden;
}

a {
	color: $color-latter;
	text-decoration: none;
}

.flex-1 {
	flex: 1 0 auto;
}

.flex-align-center {
	align-items: center;
}

.flex-justify-center {
	justify-content: center;
}

.flex-justify-end {
	justify-content: flex-end;
}

.flex-justify-space-between {
	justify-content: space-between;
}
.gold {
	background-color: #e8d070;
	background-image: linear-gradient(158deg, #f5e992 0%, #d1a22e 100%);
}

.shadow {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
}

.search-button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $heading-font-family;
	font-weight: $global-weight-bold;
	font-size: 28px;
	color: #ffffff;
	letter-spacing: 0;
	text-shadow: 0 1px 2px rgba(197, 150, 35, 0.63);
	text-decoration: none;
	user-select: none;

	&:hover {
		cursor: pointer;
	}
}

.button-wrapper {
	padding: 1px;
	border-radius: 30px;
	display: inline-block;
	background: rgba(197, 150, 35, 0.63);
	background-image: linear-gradient(
		-90deg,
		#d1a22e 0%,
		#f1e187 30%,
		#f1e187 60%,
		#edd97c 66%,
		#d1a22e 100%
	);
	line-height: 1;

	&.disabled {
		background: grey;
		color: grey;
	}

	a,
	span {
		border-radius: inherit;
		font-weight: 400;
		text-decoration: none;
		color: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		background-color: #fff;
		font-family: $heading-font-family;
		font-weight: $global-weight-bold;
		letter-spacing: 0;
		width: 123px;
		height: 44px;
		transition: color 300ms $transition;

		@include breakpoint(small only) {
			font-size: rem-calc(14px);
			width: 100px;
			height: 38px;
		}

		&:hover {
			color: lighten($color-latter, 15%);
		}
	}
}

#search-wrapper {
	display: flex;
	height: 96px;
	width: 1162px;
	margin: 0 auto;
	background: #fff;
	margin-bottom: 200px;

	.option-wrapper {
		position: relative;
		display: flex;
		flex: 1 0 calc(33.33% - 177px);
		padding: 20px 0;
		user-select: none;
		z-index: 1;

		&:hover {
			cursor: pointer;
		}

		&.open {
			background: #fcfcfb;
			z-index: 10;

			&:nth-child(2) .option {
				border-color: transparent;
			}

			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -1px;
				width: 1px;
				content: "";
				background: #ebe8e2;
			}

			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: -1px;
				width: 1px;
				content: "";
				background: #ebe8e2;
			}

			.option:after {
				background-color: #e8d070;
				background-image: linear-gradient(
					-94deg,
					#d1a22e 4%,
					#f1e187 33%,
					#f1e187 69%,
					#d1a22e 100%
				);
			}

			.icon {
				display: none !important;
			}

			.icon-close {
				display: flex !important;

				&:after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					content: "";
				}
			}

			.dropdown {
				display: block;
			}
		}

		&:nth-child(2) .option {
			border-right: 1px solid transparent;
			border-left: 1px dotted transparent;
			border-image: url(/dist/images/border.png);
			border-image-width: 4px;
			border-image-repeat: repeat;
			border-image-slice: 1px;
		}

		.option {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1 0 auto;
			padding: 0 28px;

			&:after {
				position: absolute;
				content: "";
				top: 0;
				right: -1px;
				left: -1px;
				height: 2px;
				z-index: 10;
			}

			label {
				opacity: 0.38;
				font-size: 12px;
				color: #000000;
				letter-spacing: 1.15px;
			}

			h3 {
				margin: 0;
				font-size: 20px;
				color: #000000;
				letter-spacing: 0;
			}

			.icon,
			.icon-close {
				height: 32px;
				width: 32px;

				display: flex;
				align-items: center;
				justify-content: center;

				&.hidden {
					display: none;
				}
			}

			.icon-close {
				display: none;
			}
		}

		.dropdown {
			display: none;
			position: absolute;
			right: 0;
			left: 0;
			bottom: -420px;
			height: 380px;
			background: #ffffff;
			margin-bottom: 40px;
			border-top: 1px solid #ebe8e2;
			border-radius: 0 0 5px 5px;

			&.small {
				height: 145px;
				bottom: -185px;
			}

			&:before {
				position: absolute;
				top: -9px;
				right: 37px;
				content: "";
				background: #fff;
				height: 16px;
				width: 16px;
				transform: rotate(45deg);
				border-top: 1px solid #ebe8e2;
				border-left: 1px solid #ebe8e2;
			}

			ul {
				margin: 0;
				padding: 28px;
				list-style: none;
				max-height: 100%;
				overflow: auto;

				li {
					display: block;
					padding: 12px;
					font-size: 13px;
					letter-spacing: 0;
					line-height: 17px;
					border-radius: 2px;
					background: transparent;
					transition: all 400ms $transition;
					margin-bottom: 2px;

					&.selected {
						background: #f6f1f5;
						color: $color-latter;

						&:hover {
							background: #f6f1f5;
							color: $color-latter;
							cursor: default;
						}
					}

					&:hover {
						background: rgba(#000, 0.025);
						cursor: pointer;
					}

					span {
						font-size: 11px;
						display: block;
						opacity: 0.6;
						margin-bottom: -4px;
					}
				}
			}
		}
	}
}

.grid-container.narrow {
	max-width: 1440px;
}

.bg-light,
.bg-darker {
	padding: 3rem 0;

	@include breakpoint(medium up) {
		padding: 4rem 0;
	}

	@include breakpoint(large up) {
		padding: 5rem 0;
	}

	@include breakpoint(xlarge up) {
		padding: 7rem 0;
	}
}

.bg-light {
	background-color: #fbfbfa;
}

.bg-darker {
	background-color: #f8f7f5;
	border-top: 1px solid #f1eeeb;
	border-bottom: 1px solid #f1eeeb;
}

@import "kristian";

img {
	transition: 400ms $transition;
	opacity: 1;

	&.lazyload {
		opacity: 0;
	}

	&.lazyloaded {
		opacity: 1;
	}
}

.paginate-navigate {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	width: 90%;
	max-width: 620px;
	font-size: 18px;

	&.top {
		padding-bottom: 80px;
	}

	&.bottom {
		padding-top: 40px;
	}

	.disabled {
		color: #000;
		opacity: 0.4;
	}

	.pages {
		a {
			display: inline-block;
			text-align: center;
			padding: 5px 10px;
			background: #fff;
			margin-right: 5px;
			transition: 400ms $transition;

			&.current,
			&:hover {
				background: $color-latter;
				color: #fff;
			}

			@include breakpoint(small down) {
				margin-bottom: 10px;
			}
		}
	}
}

.restaurant-menu {
	padding-bottom: 6rem;
}

.slick-track {
	display: flex;

	.card,
	.item {
		margin: 0 1.5rem 0 0;
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		height: auto;

		.button-wrapper span {
			width: 100%;
			padding: 0 14px;
		}

		@include breakpoint(small only) {
			margin: 0 1rem 0 0;
		}
	}
}

#showCalendar {
	.infobox {
		padding: 0;
		padding-bottom: 2.5rem;
		min-height: auto;
	}
	.info {
		text-align: center;
		padding-bottom: 2.5rem;
	}
}

.performance-intro {
	padding: 0.55rem 1rem 0.75rem 1rem;
	background: #f8f7f5;
	color: rgba($black, 0.75);
	border-bottom: 1px solid #f1eeeb;
	font-size: rem-calc(14px);
	font-weight: $global-weight-regular;

	@include breakpoint(small only) {
		font-size: rem-calc(13px);
	}
}

.background-pattern {
	background-color: $header-background-color;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url(/dist/images/bg-dots.svg);
		filter: blur(6px);
		opacity: 0.2;
		z-index: -1;
	}
}

#top-banner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(#000, 0.8);
	background-image: url("/dist/images/loading.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100px;

	.top-banner-slider {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		height: 0;
		transition: 0.2s ease-in-out;

		&.slick-initialized {
			height: auto;
			opacity: 1;
		}

		.slider-arrow {
			position: absolute;
			top: 20%;
			bottom: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			z-index: 10;

			@include breakpoint(medium down) {
				opacity: 0;
			}

			&.right {
				right: 5%;
			}

			&.left {
				left: 5%;
			}

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				width: 48px;
				border-radius: 100%;
				background: rgba(white, 0.15);
				transition: all 0.2s ease-in-out;

				&:hover {
					cursor: pointer;
					background: rgba(white, 0.4);
				}

				img {
					width: 40px;
				}
			}
		}

		.slick-list,
		.slick-track {
			height: 100%;
		}

		.slick-list {
			padding: 0 !important;
		}

		.slick-dots {
			position: absolute;
			bottom: 120px;
			right: 0;
			left: 0;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			list-style: none;
			padding: 0;

			@include breakpoint(medium down) {
				bottom: 45px;
			}

			li {
				margin: 0;
				margin-right: 7px;

				&:last-of-type {
					margin-right: 0;
				}

				&:only-child {
					opacity: 0;
				}

				button {
					display: inline-block;
					width: 14px;
					height: 14px;
					border-radius: 100%;
					color: transparent;
					background: rgba(white, 0.3);
				}

				&.slick-active {
					button {
						background: #fff;
					}
				}
			}
		}
	}

	.slick-track > div {
		position: relative;
		background-position: center center;
		background-size: cover;

		.show-info {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			@include breakpoint(small down) {
				padding: 0 1rem;
			}

			.card {
				min-height: 144px;
				max-width: 800px;
				margin-bottom: -72px;
				margin-right: 0;
				box-shadow: 0 2px 79px 0 rgba(0, 0, 0, 0.08),
					0 15px 114px 0 rgba(0, 0, 0, 0.08);
				padding: 0 45px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;

				h1 {
					margin: 0;
					font-size: 34px;
					line-height: 38px;
				}
				h2 {
					margin: 0;
					font-size: 17px;
					opacity: 0.8;
					font-weight: 300;
				}

				.button-wrapper span {
					padding: 0 20px;
				}

				@include breakpoint(medium down) {
					width: 100px;
					padding: 0 20px;
					min-height: 72px;
					margin-bottom: -36px;

					h2 {
						font-size: 12px;
					}

					h1 {
						font-size: 20px;
						line-height: 19.5px;
					}
				}
			}
		}
	}

	.responsive-image {
		position: relative;

		div {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-position: center center;
			background-size: cover;
		}
	}

	.responsive-video {
		position: relative;

		.video-wrapper {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			> video {
				position: absolute;
				right: 0;
				bottom: 0;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				z-index: -100;
				background-size: cover;
				overflow: hidden;
			}
		}
	}

	.responsive-show {
		position: relative;

		.show-image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-position: center center;
			background-size: cover;
		}
	}
}

.has-banner {
	.show-info-wrapper {
		position: absolute;
		bottom: -94px;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;


		@include breakpoint(1300px down) {
			bottom: -52px;
		}

		.show-info {
			color: #000;
			width: 1246px;
			max-width: 95%;
			background: #FFF;
			box-shadow: 0 2px 79px 0 rgba(0, 0, 0, 0.08),
					0 15px 114px 0 rgba(0, 0, 0, 0.08);
			
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px 68px 12px 12px;

			@include breakpoint(1300px down) {
				padding: 12px;
			}

			h1,
			h2 {
				margin: 0;
				padding: 0;
			}

			h1 {
				font-size: 38px;
				font-weight: medium;
				line-height: 39px;

				@include breakpoint(1300px down) {
					font-size: 20px;
					line-height: 19.5px;
				}
			}

			h2 {
				font-size: 18px;
				opacity: 0.8;
				line-height: 39px;
				@include breakpoint(1300px down) {
					font-size: 12px;
					line-height: 19.5px;
				}
			}

			.left {
				display: flex;
				align-items: center;
				flex: 1 0 0;

				.img {
					padding-right: 32px;
				}

				.comedian-image {
					position: relative;

					content: "";
					position: absolute;
					top: 2px;
					left: 2px;
					bottom: 2px;
					right: 2px;
					border: 4px solid #fff;
					z-index: 2;
					background-clip: padding-box;
					border-radius: 100%;

					img {
						border-radius: 100%;
					}
				}

				img {
					width: 160px;
					height: auto;

					@include breakpoint(1300px down) {
						width: 80px;
					}
				}
			}

			.right {
				display: flex;
				align-items: center;

				a {
					color: #000;
				}

				.button-wrapper {
					span {
						width: auto;
						padding: 0 1rem;
					}
				}

				.info-block {
					position: relative;
					display: flex;
					align-items: center;
					padding: 0 24px;
					font-size: 16px;

					@include breakpoint(medium down) {
						display: none;
					}

					&:before {
						content: '';
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						background-image: url(/dist/images/border.svg);
						background-repeat: repeat-y;
						background-position: center -1px;
						width: 5px;
					}


					&.last {
						border: none;
						padding-right: 48px;

						&:before {
							display: none;
						}
					}

					img {
						margin-right: 13px;
					}
				}
			}
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

#top-banner {
	.show-background {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		background-size: cover;
		background-position: center center;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 50%;
		background: blue;
		background: linear-gradient(to bottom, rgba(0,0,0, 1) 0%,rgba(0,0,0,0) 100%);
	}
}