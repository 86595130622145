
.matrix {
	padding-bottom: 3rem;

	@include breakpoint(medium up) {
		padding-bottom: 4rem;
	}

	@include breakpoint(large up) {
		padding-bottom: 4rem;
	}

	@include breakpoint(xlarge up) {
		padding-bottom: 6rem;
	}
}

.youtube {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	width: 100%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
