/* --------------------------------------------------------
 _accordion.scss 
 --------------------------------------------------------*/

.accordion {
	width: 100%;
	
	ul {
		list-style: none;
		margin:0;
		padding:0;

		li {
			margin:0;
			padding:0;
			width: 100%;

			.accordion-item {
				background: $light-background-color;
				border: 1px solid $light-border-color;
				border-radius: 2px;
				padding: 0 1.5rem;
				display: block;
				width: 100%;
				position: relative;
				cursor: pointer;
				transform: translate3d(0,0,0);
				transition: 300ms $transition;

				@include breakpoint(xlarge up) {
					
					&:hover {
						transform: scale(1.005);
						box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
					}
				}

				@include breakpoint(medium up) {
					padding: 0 2.5rem;
					border-radius: 4px;
				}

				.accordion-title {
					font-family: $heading-font-family;
					font-weight: $global-weight-regular;
					color: $black;
					font-size: rem-calc(16px);
					margin: 0;
					height: 96px;
					display: flex;
					width: 100%;
					align-items: center;
					position: relative;
					line-height: 1.5;
					padding-right: 48px;
					transition: color 300ms $transition;

					@include breakpoint(medium up) {
						font-size: rem-calc(20px);
					}

					&:after {
						content:"";
						width: 1.5rem;
						height: 1.5rem;
						background-image: url(/dist/images/accordion-arrow.svg);
						position: absolute;
						top: 50%;
						margin-top: -0.75rem;
						right: -4px;
					}
				}

				&:hover .accordion-title {
					color: lighten($color-latter, 15%);
				}

				.accordion-content {
					margin: -1.25rem 0 2.25rem 0;
					font-size: rem-calc(18px);
					display: none;

					@include breakpoint(small only) {
						font-size: rem-calc(14px);
						margin: -1rem 0 1.5rem 0;
					}
				}
			
				&.active {
					background-color: $white;
					box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);

					.accordion-title {
						&:after {
							transform: rotate(-180deg);
						}
					}

					.accordion-content {
						display: block;
					}
				}

				margin-bottom: 0.75rem;

				@include breakpoint(small only) {
					margin-bottom: 0.5rem;
				}

			}

			&:last-child {
				.accordion-item {
					margin-bottom: 0;
				}
			}
		}
	}

}