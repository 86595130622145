#search {
    background: #FFFFFF;
    box-shadow: 0 15px 114px 0 rgba(0,0,0,0.08);

    @include breakpoint(medium down) {
        margin-bottom: 40px;
    }

    .left {
        border-right: 1px solid #EEEEEE;

        #calendar {
            padding: 0;
        }

        .reset {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .left,
    .right {
        padding: 38px;
    }

    .right {
        position: relative;
    }

    .label {
        label {
            font-size: 12px;
            font-weight: 400;
        }

        h3 {
            font-size: 20px;
        }
    }

    .option {
        background-image: url('/dist/images/up-down.svg');
        background-position: 100% 50%;
        background-repeat: no-repeat;

        margin-bottom: 22px;

        &:hover {
            cursor: pointer;
        }

        .heading-row {
            margin: 0;
        }
    }

    .options {
        display: none;
        background: #FFF;
        overflow-y: auto;

        &.open {
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
        }

        .close {
            position: absolute;
            top: 13px; right: 13px;

            &:hover {
                cursor: pointer;
            }
        }

        .search-show {
            display: block;
            width: 100%;
            border: none;
            box-shadow: 0 1px 0 0 rgba($black, 0.07);
            height: 60px;
            padding: 0 20px;
            font-size: 1rem;
            letter-spacing: 0;
            background: #fff;
    
            &:focus {
                outline: none;
            }
    
            &::placeholder {
                color: rgba($black, 0.4);
            }
        }

        ul {
			margin: 0;
			padding: 16px;
			list-style: none;

			li {
				display: block;
				padding: 12px;
				font-size: 13px;
				letter-spacing: 0;
				line-height: 17px;
				border-radius: 2px;
				background: transparent;
				transition: all 400ms $transition;
				margin-bottom: 2px;
				font-weight: bold;

				&.selected {
					background: #F6F1F5;
					color: $color-latter;

					span {
						color: $color-latter;
					}

					&:hover {
						background: #F6F1F5;
						color: $color-latter;
						cursor: default;
					}
				}

				&:hover {
					background: rgba(#F6F1F5, 0.75);
					cursor: pointer;
				}

				span {
					font-size: 11px;
					display: block;
					color: #666666;
					margin-bottom: -4px;
				}
			}
		}
    }

    .button-wrapper {
        width: 100%;
        margin-top: 28px;

        a {
            display: inline-flex;
            width: 100%;
            font-size: 17px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.bg-light.featured {
    padding-top: 180px;

    @include breakpoint(medium down) {
        padding-top: 80px;
    }
}