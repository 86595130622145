$padding: 15px 16px;
$green: rgb(46, 204, 113);
$red: #e74c3c;
$purple: rgb(100, 19, 77);
$gold: #EECC78;

#become-member-signup {
  background: rgb(41,0,112);
  background: linear-gradient(222deg, rgba(41,0,112,1) 0%, rgba(32,13,36,1) 48%, rgba(64,32,91,1) 100%);
  margin: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;

  .grid-container {
    //margin: 2rem;
    max-width: 100%;
    width: 100%;
    padding: 0;
    position: relative;

    .signup-intro-video {
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 100%;
      top: 0;

      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .logo-wrap {
      background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      padding: 2rem;

    

      .logo {
        width: 350px;
      }
    }  
    
    .form-title {
      color: #fff;
      text-shadow: 0 2px 80px rgba(0,0,0,.3),0 2px 10px rgba(0,0,0,.85);
      font-weight: 700 !important;
    }

    .form-content {
      position: relative;
      width: 100%;
      top: 14rem;
    }

    button {
      font-size: 1.4em;
      padding: 20px 25px;
      border: 3px solid $gold;
      border-radius: 15px;
      height: auto;
      width: 300px;
    }
    // .start-button {
    //   position: absolute;
    //   background: rgba($purple, 0.8);
    //   color: #FFF;
    // }
    .become-member-form {
      text-align: center;
      background: #FFF;
      box-shadow: 0 0 5px rgba(0,0,0,.85);
      border-radius: .2rem;
      padding: 1.2rem;
      align-items: center;
      font-family: "Brandon Grotesque",sans-serif;
      font-size: 28px;
      color: #000;
      letter-spacing: 0;
      position: relative;
      min-height: 550px;
      .input-group {
        //background-image: linear-gradient(-110deg, #D1A22E 0%, #F1E187 30%, #F1E187 60%, #EDD97C 66%, #D1A22E 100%);
        margin-bottom: 1rem;
        padding: 3px;
        width: 100%;
        align-items: center;
        p {
          margin-top: 2rem;
        }
      }

      label {
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      input:not([type="checkbox"]) {
        border: 3px solid $light-border-color;
        -webkit-appearance: none;
        padding: $padding;
        text-align: center;
        width: 100%;
        margin-bottom: 2rem;
        border-radius: 15px;

        &:active,
        &:focus {
          border: 3px solid $purple;
        }
      }

      .invalidInput {
        outline: 2px $red dashed !important;
      }

      .errorMessage {
        position: absolute;
        width: 200px;
        right: -220px;
        background: grey;
        font-size: 16px;
        padding: 5px;
        border-radius: 3px;
        color: #fff;
      }

      .error-list {
        list-style: none;
        color: $red;
      }
      // .submit-button {
      //   margin:1em 0 2em;
      // }
      .refresh-button {
        padding: 1em;
        height: auto;
        width: auto;
        font-size: 1rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      .two-buttons {
        display: flex;
        justify-content: space-between;
      }

      .gdpr-container {
        margin-bottom: 2rem;
      }

      .gdprAccept {
        margin: 0 0.5rem;
        border: 2px solid $light-border-color;
        width: 1.7em;
        height: 1.7em;
        display: inline-block;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;

        i {
          color: #fff;
        }

        .not-accepted {
          color: $light-border-color;
        }
      }

      .accepted {
        background-color: $green;
        border-color: $green;
      }

      .steps-container {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin: 1em 0 2em;

        .steps {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 200px;

          .step {
            width: 20px;
            height: 20px;
            background: $light-border-color;
            border: 2px solid $light-border-color;
            border-radius: 50%;
            transition: background 1s;
          }

          .step.selected {
            border: 2px solid #D1A22E;
            background: #fff;
          }

          .step.completed {
            background: rgb(100, 19, 77);
            border-color: rgb(100, 19, 77);
          }
        }
      }
    }
    .introStep {
      background: none;
      box-shadow: none;
      display: flex;
      justify-content: center;
    }
  }
  // grid-container
}
// #become-member-signup
