/* brandon grotsesque - bold (700), medium (500), regular (400) */
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/dist/fonts/brandon_bld.eot');
  src: url('/dist/fonts/brandon_bld.eot?#iefix') format('embedded-opentype'),
     url('/dist/fonts/brandon_bld.woff') format('woff'),
     url('/dist/fonts/brandon_bld.ttf') format('truetype'),
     url('/dist/fonts/brandon_bld.svg#brandon-grotesque-bold') format('svg');
  font-weight: $global-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/dist/fonts/brandon_med.eot');
  src: url('/dist/fonts/brandon_med.eot?#iefix') format('embedded-opentype'),
     url('/dist/fonts/brandon_med.woff') format('woff'),
     url('/dist/fonts/brandon_med.ttf') format('truetype'),
     url('/dist/fonts/brandon_med.svg#brandon-grotesque-medium') format('svg');
  font-weight: $global-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/dist/fonts/brandon_reg.eot');
  src: url('/dist/fonts/brandon_reg.eot?#iefix') format('embedded-opentype'),
     url('/dist/fonts/brandon_reg.woff') format('woff'),
     url('/dist/fonts/brandon_reg.ttf') format('truetype'),
     url('/dist/fonts/brandon_reg.svg#brandon-grotesque-regular') format('svg');
  font-weight: $global-weight-regular;
  font-style: normal;
}

/* lato-100 - latin */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 100;
     src: local('Lato Hairline'), local('Lato-Hairline'),
          url('/dist/fonts/lato-v14-latin-100.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-100.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-100italic - latin */
@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 100;
     src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
          url('/dist/fonts/lato-v14-latin-100italic.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-100italic.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-300 - latin */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 300;
     src: local('Lato Light'), local('Lato-Light'),
          url('/dist/fonts/lato-v14-latin-300.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-300.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-300italic - latin */
@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 300;
     src: local('Lato Light Italic'), local('Lato-LightItalic'),
          url('/dist/fonts/lato-v14-latin-300italic.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-300italic.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 400;
     src: local('Lato Regular'), local('Lato-Regular'),
          url('/dist/fonts/lato-v14-latin-regular.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-regular.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-italic - latin */
@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 400;
     src: local('Lato Italic'), local('Lato-Italic'),
          url('/dist/fonts/lato-v14-latin-italic.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-italic.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700 - latin */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 700;
     src: local('Lato Bold'), local('Lato-Bold'),
          url('/dist/fonts/lato-v14-latin-700.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-700.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-700italic - latin */
@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 700;
     src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
          url('/dist/fonts/lato-v14-latin-700italic.woff2') format('woff2'),
          /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('/dist/fonts/lato-v14-latin-700italic.woff') format('woff');
     /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}