html.nav-open {
	overflow: hidden;

	body {
		overflow: hidden;
	}

	#nav {
		@include breakpoint(medium down) {
			position: fixed;
			height: 100vh;
			right: 0;
			left: 0;
			overflow: auto;
			z-index: 9999;
			background-color: #2D0933;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: url(/dist/images/bg-dots.svg);
				filter: blur(6px);
				opacity: 0.25;
				z-index: -1;
			}

			#logo {
				opacity: 0;
			}

			.menu-items {
				order: 0;
				margin-bottom: 24px;

				.menu-button {
					display: none;

					&.close {
						display: block !important;
					}
				}
			}

			.right,
			.left {
				padding: 0 48px;
				display: flex !important;
				order: 2;
				flex-direction: column;
				margin-bottom: 35px;
				align-items: flex-start !important;

				.nav-item {
					margin: 0;
					margin-bottom: 20px;
				}
			}

			.left {
				position: relative;
				padding-bottom: 20px;

				.nav-item:last-of-type:after {
					content: '';
					height: 1px;
					right: 48px;
					left: 48px;
					bottom: 0;
					background-image: url(/dist/images/border-light.svg);
					background-position: bottom left;
					position: absolute;
				}
			}
		}
	}
}

#nav {
	padding-top: 64px;
	padding-bottom: 106px;
	font-family: $heading-font-family;

	@include breakpoint(medium down) {
		padding-top: 48px;
		padding-bottom: 60px;
	}

	@include breakpoint(small down) {
		padding: 1.5rem 0 2.5rem 0;
	}

	.menu {
		width: 100%;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			justify-content: flex-end;

			@include breakpoint(medium down) {
				flex-direction: column;
			}

			li {
				display: inline-block;
				margin-right: 1.5rem;
				margin-bottom: 0;
				font-size: 20px;
				color: #fff;
				
				&:last-of-type {
					margin: 0;
				}

				@include breakpoint(medium down) {
					display: block;
					width: 100%;
					margin-bottom: 20px;
					font-size: 17px;
				}

				&:hover {
					color: #F4E68E;
				}

				a {
					color: inherit;
				}
			}
		}
	}

	.menu-items {
		position: relative;

		.menu-button {
			display: none;
			position: absolute;
			right: 1.5rem;
			width: 24px;
			text-align: center;
			z-index: 2;

			&:hover {
				cursor: pointer;
			}

			@include breakpoint(medium down) {
				display: block;
			}

			&.close {
				display: none;
			}
		}
	}

	.grid-x {
		.cell {
			display: flex;
			align-items: center;
		}
	}

	#logo {
		
		width: 124px;
		z-index: 9;

		@include breakpoint(small down) {
			max-width: 100px;
			position: relative;
			top: 2px;
		}

		@include breakpoint(xlarge up) {
			width: 138px;
		}

		svg {
			width: 100%;
			height: auto;
		}
	}

	.nav-item {
		display: flex;
		margin-right: 1.25rem;
		font-size: 1.0625rem;
		height: 100%;
		line-height: 1;
		align-items: center;
		text-decoration: none;
		color: #fff;
		transition: color 400ms $transition;

		@include breakpoint(xlarge up) {
			margin-right: 1.5rem;
			font-size: 20px;
		}

		&:hover {
			color: #F4E68E;

			.stroke path {
				stroke: #F4E68E; 
			}

			.fill path {
				fill: #F4E68E;
			}
		}

		&:last-of-type {
			margin: 0;
		}

		.stroke path {
			stroke: #fff;
			transition: stroke 400ms $transition;
		}

		.fill path {
			fill: #fff;
			transition: fill 400ms $transition;
		}

		svg {
			margin-right: 7px;
			position: relative;
			top:-1px;
		}
	}

	.grid-x .cell:first-of-type {
		
		.nav-item {

			margin-right: 1.25rem;

			&:last-child {
				margin-right: 0;
			}

			@include breakpoint(xlarge up) {
				margin-right: 1.75rem;
			}

			@include breakpoint(small only) {
				font-weight: 500;

				svg {
					width: 32px;
				}

				&:nth-child(2) {
					svg {
						position: relative;
						left: -3px;
					}
				}

				&:nth-child(3) {
					svg {
						position: relative;
						left: 3px;
					}
				}
			}
			
			&:nth-child(2) {
				svg {
					position: relative;
					top:-1px;
				}
			}
		}
	}
}


.times {
	.cell {
		padding-left: 0 !important;
		margin-bottom: 5px;
	}

	.time {
		color: $color-latter;
		font-weight: 500;

		&.closed {
			color: grey;
		}
	}
}

.contactInformation {
	h1,h2,h3,h4 {
		font-size: 16px;
		color: #000;
		//font-weight: 400;
		margin-top: 0;
	}

	a {
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 1;
		font-weight: 400;
	}

	p {
		padding-bottom: 10px;
	}
}