/* Foundaton */
$global-width: 1630px;
$grid-margin-gutters: 32px;
$white: #fff;

$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1300px,
  xxlarge: 1750px,
);

$breakpoint-classes: (small medium large xlarge xxlarge);

/* Custom */
$nav-height: 86px;
$nav-height-mobile: 50px;
$transition: cubic-bezier(.25,.8,.25,1);

$color-latter: #64134D;
$color-background-light: #fcfcfb;
$color-background-dark: #f9f8f6;


/* font families */
$body-font-family: "Lato", sans-serif;
$heading-font-family: "Brandon Grotesque", sans-serif;

/* font weights */
$global-weight-bold: 700;
$global-weight-medium: 500;
$global-weight-regular: 400;
$global-weight-light: 300;

/* sizing variables */
$content-max-width: 2560px;
$header-min-height: 425px;

/* colors */
$header-background-color: #2D0933;

$white: #fff;
$black: #000;

$light-background-color: #F8F7F5;
$lighter-background-color: #FBFBFA;

$light-border-color: #E5E5DE;
$lighter-border-color: #F1EEEB;