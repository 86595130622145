/* --------------------------------------------------------
 _cards.scss 
 --------------------------------------------------------*/

 .grid-container.cards {
	margin-bottom: 0rem;

	@include breakpoint(medium up) {
		margin-bottom: 2rem;
	}

	&.vip {
		padding-top: 2rem;
		margin-bottom: 2rem;

		@include breakpoint(small only) {
			margin-bottom: 0;
		}
	}

	.cell {
		margin-bottom: 2rem;
	}

	&:only-of-type, &:last-of-type {
		margin-bottom: -2rem;
	}
}

a.card {
	transform: translate3d(0,0,0);
	transition: 300ms $transition;
	outline: none;
	
	.button-wrapper {
		transition: 300ms $transition;
	}
}

a.card, .show-card {
	transition: 300ms $transition;
	@include breakpoint(large up) {
		&:hover {
			transform: scale(1.015);
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);

			.button-wrapper {
				color: lighten($color-latter, 15%);
			}
		}
	}
}

.card {
	width: 100%;
	background-color: $white;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06);
	width: 100%;
	padding: 6rem;
	display: block;
	color: $black;

	&.map {
		padding-bottom: 55%;
		position: relative;
		border: 12px solid $white;

		@include breakpoint(small only) {
			border: 4px solid $white;
		}
	}

	.button-wrapper {

	}

	&.split {

		&.single {
			padding: 0;
			display: block;
			padding: 3rem 0;

			@include breakpoint(small only) {
				padding: 2.5rem 1.5rem;
			}

			h3 {
				margin: 0;
				font-size: 28px;
				text-transform: capitalize;
				font-weight: normal;

				@include breakpoint(small only) {
					font-size: rem-calc(20px);
				}
			}

			> .grid-x {
				height: auto;
				flex: 1 0 auto;
				align-items: center;

				@include breakpoint(small only) {
					flex-wrap: wrap;					
				}

				.cell {
					flex: 1 0 auto;
					align-items: center;
					display: flex;
					height: 100%;

					&.center {
						justify-content: center;
					}

					&:first-of-type {
						align-items: flex-start;
						display: flex;
						flex-wrap: wrap;

						* {
							text-align: left;
							width: 100%;

							@include breakpoint(small only) {
								text-align: center;
							}
						}
					}
				}
			}
		}

		padding: 4rem 1rem;

		@include breakpoint(small only) {
			padding: 2rem 0.5rem;
		}

		p {
			font-size: 1rem;
			line-height: 1.9;
		}

		.contactInformation p {
			padding-bottom: 0;
		}

		.contactInformation a:not(.large-tel) {
			font-size: inherit;
			line-height: inherit;
		}

		> .grid-x {
			> .cell {
				
				position: relative;
				padding: 0 3rem;

				@include breakpoint(small only) {
					margin-bottom: 2rem;
					padding: 0 1.5rem;
					padding-bottom: 2rem;
				}

				&:after {
					content: "";
					position: absolute;
					width: 1px;
					top: 0;
					bottom: 0;
					right: 0;
					background-image: url(/dist/images/border.svg);
					background-position: bottom left;
					background-repeat: repeat;

					@include breakpoint(small only) {
						width: 100%;
						bottom: 0;
						height: 3px;
						top: auto;
						left:0;
						right: 0;
						background-repeat: repeat-x;
	    				background-position: center -1px;
					}
				}

				&:last-child {
					&:after {
						display: none;
					}

					@include breakpoint(small only) {
						padding-bottom:0;
						margin-bottom: 0;
					}
				}
			}
		}
		
	}

	&.foodmenu {
		padding: 4rem 3rem;
		margin-top: 2.75rem;
		display: none;

		@include breakpoint(small only) {
			padding: 2rem 1.5rem;
			margin-top: 1.5rem;
		}

		&.visible {
			display: block;
		}

		.foodmenu-list {

			font-family: $heading-font-family;
			margin-top: 2rem;

			.foodmenu-item {

				width: 100%;
				padding: 1.5rem 0;
				display: flex;
				align-items: center;
				position: relative;

				* {
					text-align: left;
				}

				&.subtitle {
					font-style: italic;
					padding-top: 80px;
				}

				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 3px;
					bottom: 0px;
					left: 0;
					background-image: url(/dist/images/border.svg);
					background-position: center -1px;
					background-repeat: repeat-x;
				}

				&:last-of-type:after {
					display: none;
				}

				.item-content {
					
					flex-grow: 1;

					.title {
						font-size: rem-calc(22px);

						@include breakpoint(small only) {
							font-size: rem-calc(16px);
							line-height: 1.3;
							margin-bottom: 5px;
						}
					}

					.subtitle {
						font-size: rem-calc(14px);
						opacity: 0.6;

						@include breakpoint(small only) {
							font-size: rem-calc(12px);
						}
					}
				}

				.item-price {
					flex-shrink: 1;
					text-align: right;
					font-size: rem-calc(14px);
				}
			}

			.foodmenu-footer p {
				margin-top: 2rem;
				font-family: $body-font-family;
				font-weight: $global-weight-regular;
				font-size: 1rem;

				@include breakpoint(small only) {
					font-size: rem-calc(14px);
					margin-top: 1rem;
				}
			}
		}

	}

	.rounded-image {
		width: 100%;
		display: block;
		padding-bottom: 100%;
		border-radius: 1000px;
		position: relative;
		overflow: hidden;
		background-image: linear-gradient(-94deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
		margin-bottom: 1.5rem;
		background-clip: padding-box;

		&:after {
			content:"";
			position: absolute;
			top: 2px;
			left: 2px;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			border: 4px solid $white;
			border-radius: 1000px;
			z-index: 2;
			background-clip: padding-box;
		}

		.image-container {
			position: absolute;
			top: 4px;
			left: 4px;
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			border-radius: 1000px;
			z-index: 1;
			overflow: hidden;
			background-clip: padding-box;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.rounded-icon {
		width: 8rem;
		height: 8rem;
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		border-radius: 1000px;
		position: relative;
		overflow: hidden;
		margin-bottom: 1.5rem;
		background-clip: padding-box;
		background-color: $header-background-color;
		display: flex;
		align-items: center;
		justify-content: center;

		span.number {
			background: linear-gradient(-142deg, #F5E992 0%, #D1A22E 100%);
			font-size: 47px;
			color: #F4E68E;
			font-family: $heading-font-family;
			font-weight: $global-weight-bold;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			position: relative;
			top: 2px;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: url(/dist/images/bg-dots.svg);
			filter: blur(6px);
			opacity: 0.25;
			z-index: 0;
		}

		svg {
			position: relative;
			z-index: 1;
		}
	}

	&.info-card, &.icon-card {
		padding: 2.5rem 4rem;
		text-align: center;
		height: 100%;
		align-items: center;

		&.small {
			padding: 2rem 1.5rem;

			h5 {
				font-size: 1rem;
			}

			.rounded-image {
				margin-bottom: 1rem;
				width: calc(100% - 3rem);
				margin-left: 1.5rem;
				padding-bottom: calc(100% - 3rem);
			}

			p:last-of-type {
				margin-bottom:-6px;
			}
		}

		.subtitle {
			font-size: 0.875rem;
			opacity: 0.5;
			margin-top: -1.6rem;
			margin-bottom: 0.9rem;
			display: block;
			font-weight: $global-weight-regular;
		}

		.pretitle {
			font-family: $heading-font-family;
			font-weight: $global-weight-regular;
			font-size: rem-calc(18px);
			display: block;
			margin-bottom:-3px;
		}

		.price {
			color: $color-latter;
			font-weight: $global-weight-bold;
			font-size: rem-calc(20px);
			display: block;
			margin-top: 1.5rem;
			margin-bottom:-0.25rem;
		}
	}

	&.icon-card {
		padding: 4rem 2rem;
	}

	&.gold-card {
		background-image: linear-gradient(-133deg, #F5E992 0%, #D1A22E 100%);
		background-color: transparent;
		box-shadow: none;
		position: relative;
		height: 100%;
		padding: 3.25rem;
		display: flex;
		justify-content: center;
		flex-direction: column;

		p {
			line-height: 1.8;
		}

		* {
			z-index: 1;
			position: relative;
		}


		&:after {
			content:"";
			background-color: $white;
			opacity: 0.3;
			width: calc(100% - 1rem);
			height: calc(100% - 1rem);
			position: absolute;
			top: 8px;
			left: 8px;
			z-index: 0;
		}
	}

}

/* experience cards */
#experience {

	.cell {
		&:nth-child(4) {
			@include breakpoint(large down) {
				display: none;
			}

			@include breakpoint(small only) {
				display: block;
			}
			
		}
	}

	.item {
		background-color: $white;
		position: relative;
		z-index: 1;
		padding-bottom: 100%;
		background-size: cover;
		background-position: center center;

		@include breakpoint(small only) {
			margin-bottom: 1.5rem;
		}

		@include breakpoint(medium up) {
			padding-bottom: 50%; /* square */
		}

		@include breakpoint(large up) {
			padding-bottom: 33.3333333333%; /* square */
		}

		&:before {
			position: absolute;
			content: '';
			right: 0; left: 0; bottom: 0;
			height: 70%;
			opacity: 0.95;
			background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #000000 84%);
		}

		a {
			opacity: 0;
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
		}

		.bottom {
			position: absolute;
			height: 100%;
			width: 100%;
			bottom: 0;right: 0; left: 0;
			color: #FFF;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-end;
			transition: transform 300ms $transition;
			padding: 2rem;

			@include breakpoint(medium up) {
				padding: 3rem;
			}

			@include breakpoint(large up) {
				padding: 3rem;
			}

			@media screen and (min-width: 1620px){
				padding: 4rem;
			}

			h3 {
				color: #F4E68E;
				position: relative;
				padding-right: 2rem;

				@include breakpoint(medium up) {
					font-size: rem-calc(22px);
				}

				@media screen and (min-width: 1620px){
					font-size: rem-calc(26px);
				}

				&:after {
					content: "";
					width: 1.5rem;
					height: 1.5rem;
					background: url(/dist/images/experience-arrow.svg) 0 0 no-repeat;
					display: inline-block;
					position: relative;
					top: 6px;
					left: 6px;

					@media screen and (min-width: 1620px){
						top: 4px;
						left: 6px;
						width: 1.5rem;
						height: 1.5rem;
					}
				}
			}

			p {
				color: $white;
				font-size: rem-calc(14px);
				margin-bottom:-5px;
				margin-top:-10px;
				font-weight: $global-weight-regular;
				line-height: 1.7;

				@include breakpoint(medium up) {
					margin-top:-3px;
					font-size: rem-calc(16px);
					font-weight: $global-weight-light;
				}

				@include breakpoint(xlarge up) {
					font-size: rem-calc(18px);
				}
			}
		}

		@include breakpoint(xlarge up) {
			&:hover {
				.bottom {
					transform: translate3d(0,-12px,0);
				}
			}
		}
		
	}

	.item-links {
		background-image: linear-gradient(-133deg, #F5E992 0%, #D1A22E 100%);
		position: relative;
		z-index: 1;
		padding-bottom: 0;

		@include breakpoint(medium up) {
			padding-bottom: 50%; /* square */
		}

		@include breakpoint(large up) {
			padding-bottom: 33.3333333333%; /* square */
		}

		&:before {
			position: absolute;
			content: '';
			top: 8px; right: 8px; bottom: 8px; left: 8px;
			z-index: -1;
			opacity: 0.64;
			background: rgba(255,255,255,0.42);
		}

		.grid-y {
			width: 100%;
			align-items: center;
			justify-content: center;
			display: flex;
			flex-direction: columns;

			@include breakpoint(small only) {
				padding: 2rem 0;
			}

			@include breakpoint(medium up) {
				height: 100%;
				position: absolute;
				top:0;
				left:0;
			}

			.cell {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 1.5rem 2rem;
				height: auto;
				color: $black;

				@include breakpoint(medium up) {
					padding: 2rem 3rem;
				}

				@include breakpoint(large up) {
					padding: 2rem 3rem;
				}

				@media screen and (min-width: 1620px){
					padding: 2rem 4rem;
				}

				h3 {
					font-size: rem-calc(18px);
					transition: 300ms $transition;
					padding-right: 2rem;
					margin-bottom: 0rem;

					@include breakpoint(medium up) {
						font-size: rem-calc(22px);
					}
				}

				p {
					font-size: rem-calc(14px);
					padding-right: 2rem;

					@include breakpoint(medium up) {
						font-size: 1rem;
					}
				}

				&:after {
					content: '';
					position: absolute;
					height: 1px;
					left: 2rem; 
					top: 0;
					background-image: url(/dist/images/border.svg);
					background-position: bottom left;
					width: calc(100% - 4rem);

					@include breakpoint(medium up) {
						width: calc(100% - 8rem);
						left: 4rem; 
					}
				}

				&:first-of-type {
					&:after {
						display: none;
					}
				}

				&:hover {
					h3 {
						color: lighten($color-latter, 15%);
					}
				}
			}
		}
	}
}

.matrix.first {
  

}

.slick-slide {
	&.info-card, &.icon-card {

		&.small {
			.rounded-image {
				margin-left: 0;
			}
		}
	}
}

#comedians {
	.card.gold-card {
		@include breakpoint(small only) {
			display: none;
		}
	}
}