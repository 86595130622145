/* --------------------------------------------------------
 _header.scss 
 --------------------------------------------------------*/

header {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 100;
	background-color: $header-background-color;
	color: $white;

	#comedians {
		overflow: hidden;
	}

	&.has-banner {

		@include breakpoint(medium down) {
			height: 500px !important;
		}

		@include breakpoint(small down) {
			height: 360px !important;
		}
	}

	.hero-heading {

		margin-bottom: 4rem;

		h1 {
			margin:0;
		}
		
		@include breakpoint(medium up) {
			margin-bottom: 5rem;
			margin-top: -1rem;
		}

		@include breakpoint(large up) {
			margin-bottom: 6rem;
		}

		@include breakpoint(xlarge up) {
			
			margin-bottom: 7rem;

			h1 {
				font-size: 4rem;
			}
			
		}
		
	}
	

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url(/dist/images/bg-dots.svg);
		filter: blur(6px);
		opacity: 0.25;
		z-index: -2;
		max-height: $header-min-height;
	}

	&.frontpage {
		position: relative;

		&:after {
			min-height: 100%;
		}

		@include breakpoint(large up) {
			min-height: 750px;
			padding-bottom: 35%;
			
		}

		#nav {
			@include breakpoint(large up) {
				position: absolute;
				width: 100%;
				top:0;
				left: 50%;
				transform: translateX(-50%);
				padding-bottom:0;
				z-index: 2;
			}
		}
	}

	&.overlay {
		position: relative;
		min-height: 750px;
		height: auto;
		padding-bottom: 35%;

		&:after {
			min-height: 100%;
		}

		@include breakpoint(medium down) {
			padding-bottom: 45%;
			min-height: 0;
			
		}

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
			opacity: 1;
			filter: none;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
		}

		#nav {
			@include breakpoint(large up) {
				position: absolute;
				width: 100%;
				top:0;
				left: 50%;
				transform: translateX(-50%);
				padding-bottom:0;
				z-index: 2;
			}
		}

		.hero-heading {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top:0;
			left:0;
			margin: 0;

			h1 {
				position: relative;
				margin-top: 1rem;
				text-shadow: 0 2px 80px rgba($black, 0.3), 0 2px 10px rgba($black, 0.85);
				font-weight: 700;

				&.icon-heading {
					margin-top: 3rem;
				}

				@include breakpoint(medium up) {
					margin-top: 1.5rem;
				}

				.icon {
					
					position: absolute;
					top: -2rem;
					left: 50%;
					margin-left: -16px;

					@include breakpoint(medium up) {
						top: -3.5rem;
						margin-left: -24px;
					}
					
					svg {
						width: 32px !important;
						height: 24px !important;

						@include breakpoint(medium up) {
							width: 48px !important;
							height: 36px !important;
						}
					}

					svg path {
						fill: $white;
					}

					@include breakpoint(xlarge up) {
						top: -4.5rem;
					}
				}
			}
		}
	}
	
	/* video and image header */
	&.theme-info {

		#vue-search {
			
			@include breakpoint(large up) {
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
			}

			.grid-container {
				width: 100%;
				margin-bottom: 0;

				@include breakpoint(medium down) {
					margin-bottom: 3rem;
				}

				@include breakpoint(small only) {
					margin-bottom: 2rem;
				}
			}
		}

		.image-container {
			height: 100%;
			width: 100%;
			z-index: -1;
			overflow: hidden;
			position: absolute;
			top: 0;

			img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
			}

			&:after {
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				content: '';
				background-image: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(255,0,0,0), rgba(0,0,0,0.9));
			}
		}
	}
	
	/* show header */
	&.theme-show {
		background-position: bottom right;
		&:after {
			background-image: none;
			background-color: inherit;
			filter: none;
			opacity: 0.85;
			bottom: 0;
			height: auto;
			max-height: 100%;
		}
	}
	
	/* video container */
	.video-container {
		height: 100%;
		width: 100%;
		z-index: -1;
		overflow: hidden;
		position: absolute;
		top: 0;

		video {
			position: absolute;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -100;
			background-size: cover;
			overflow: hidden;
		}
	}

}

.nav-open .header-subnav {
	z-index: 0 !important;
}

/* subnav */
.header-subnav {
	
	display: flex;
	width: calc(100% - 3rem);
	margin-left: 1.5rem;
	justify-content: center;
	
	@include breakpoint(medium down) {
		
		position: relative;
		z-index: 1;
		top: -1.5rem;
		z-index: 101;
		margin-bottom: -1rem;

		&:after {
			content:"";
			width: calc(100% + 3rem);
			height: 100%;
			z-index: -1;
			position: absolute;
			top: 1.5rem;
			left:-1.5rem;
			background-color: $lighter-background-color;
		}
	}
	

	@include breakpoint(large up) {
		height: 6rem;
		margin:0;
		width: 100%;
	}
	
	&.sticky {
		@include breakpoint(large up) {
			position: absolute;
			position: -webkit-sticky;
			position: sticky;
			top: -3rem;
			margin-top: -6rem;
			transform: translateY(3rem);
			z-index: 999;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		background-color: $white;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06);
		padding: 2rem 1.5rem;
		width: 100%;
		position: relative;
		z-index: 2;

		@include breakpoint(large up) {
			width: auto;
			display: flex;
			flex-wrap: wrap;
			padding: 0 1rem;
		}

		@include breakpoint(xlarge up) {
			padding: 0 1.5rem;
		}

		li {
			margin:0;
			padding: 0;
			width: 100%;

			@include breakpoint(large up) {
				width: auto;
			}

			a {
				height: 2.5rem;
				display: flex;
				align-items: center;
				font-family: $heading-font-family;
				font-weight: $global-weight-normal;
				font-size: 0.875rem;
				color: rgba($black, 0.6);
				text-align: center;
				padding: 0;
				text-transform: uppercase;
				letter-spacing: 0.75px;
				position: relative;
				transition: color 400ms $transition;
				padding: 0 1rem;

				@include breakpoint(medium up) {
					height: 3rem;
				}

				@include breakpoint(large up) {
					height: 6rem;
					padding: 0 1.5rem;
					font-size: 1rem;
				}

				&:after {
					content: '';
				    position: absolute;
				    width: 100%;
				    height: 3px;
				    bottom: 0px;
				    left: 0;
				    background-image: url(/dist/images/border.svg);
				  	background-position: center -1px;
				  	background-repeat: repeat-x;
				  	display: none;

				    @include breakpoint(large up) {
				    	display: block;
				    	width: 1px;
				    	height: calc(100% - 4rem);
				    	top: 2rem;
				    	bottom: auto;
				    	right: 0;
				    	left: auto;
				    	background-position: bottom left;
				    	background-repeat: repeat;
				    	z-index: 2;
				    	
				    }
				}

				@include breakpoint(medium down) {
					&:before {
					    content: '';
					    position: absolute;
					    left: calc(-1.5rem);
					    top: 0;
					    bottom: 0;
					    width: 3px;
					    background: linear-gradient(0deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
					    display: none;
					}
				}

				&:hover {
					color: lighten($color-latter, 15%);
					cursor: pointer;
				}
			}


			&.active a {
				font-weight: $global-weight-medium;
				color: $black;
				
				@include breakpoint(large up) {
					&:before {
						content:'';
						position: absolute;
						width: calc(100% - 2rem);
						left: 1rem;
						bottom: 0;
						height: 3px;
						background-image: linear-gradient(-94deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
					}
				}

				@include breakpoint(xxlarge up) {
					width: calc(100% - 1rem);
				}

				@include breakpoint(medium down) {
					&:before {
						display: block;
					}
				}
			}

			&:last-of-type a:after {
				display: none;
			}
		}
	}
}

.header-subnav-container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@include breakpoint(xlarge up) {
		justify-content: space-between;
		flex-wrap: nowrap;
	}

	&.comedians {

		@include breakpoint(large up) {
			margin-bottom: 5rem;
		}

		@include breakpoint(xlarge up) {
			margin-bottom: 7rem;
		}

		@include breakpoint(medium down) {
			margin-bottom: -1rem;
		}

		@include breakpoint(small only) {
			margin-bottom: -3rem;
		}
	}

	.header-subnav {

		transform: translate3d(0,0,0);

		@include breakpoint(xlarge up) {
			position: static;
			bottom: auto;
			justify-content: flex-end;
		}

		@media screen and (max-width: 1620px){
			ul {

				li {
					a {
						font-size: rem-calc(14px);
						padding: 0 1rem;

						@include breakpoint(large up) {
							&:before {
								 left: calc(0.5rem);
							}
						}

						@include breakpoint(xxlarge up) {
							&:before {
								 left: calc(-1rem + 3px);
							}
						}
					}
				}
			}
		}

		@include breakpoint(medium down) {
			width: calc(100% - 1rem);
			margin-left: 0rem;
			z-index: 999;

			&:after {
				width: calc(100% + 6rem);
			    height: 100%;
			    left: -3rem;
			    background-color: $light-background-color;
			}

			ul {
				//padding-top: 1.5rem;
				//padding-bottom: 1.5rem;
			}
		}

		@include breakpoint(small only) {
			&:after {
				width: calc(100% + 4rem);
			    left: -2rem;
			}
		}


	}

	.form {
		padding: 0;
		margin: 0 auto;
		position: relative;
		width: auto;
		margin-bottom: 5rem;

		@include breakpoint(medium up) {
			margin-bottom: 6rem;
		}

		@include breakpoint(large up) {
			margin-bottom: 3rem;
		}

		@include breakpoint(xlarge up) {
			margin: 0;
			display: flex;
			align-items: center;
		}

		.icon {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -17px;

			@include breakpoint(small only) {
				margin-top: -12px;
				left: 1rem;
			}

			svg path {
				transition: fill 300ms $transition;
			}

			@include breakpoint(small only) {
				svg {
					height: 16px;
					width: 16px;
				}
			}
		}

		input[type="search"] {
			color: $white;
			box-shadow: none;
			background-color: transparent;
			border: none;
			outline: none;
			font-family: $heading-font-family;
			font-weight: $global-weight-regular;
			font-size: rem-calc(35px);
			border-bottom: 1px solid rgba(255,255,255,0.35);
			margin: -6px 0 0 0;
			width: 300px;
			max-width: 100%;
			padding-left: 2.25rem;
			padding-bottom: 2px;
			transition: border-color 300ms $transition;
			-webkit-appearance: none;
     		border-radius: 0;

			@include breakpoint(small only) {
				font-size: rem-calc(24px);
				padding-left: 1.5rem;
				margin: 0;
				width: calc(100% - 2rem);
				margin-left: 1rem;
			}

			@include breakpoint(xxlarge up) {
				width: 340px;
				font-size: rem-calc(40px);
			}

			&::placeholder {
				color: $white;
				transition: color 300ms $transition;
			}

			&:focus {
				
				border-color: #F4E68E;

				&::placeholder {
					color: rgba($white, 0.5);
				}
			}
		}
	}
}