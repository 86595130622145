header {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 100;
	background-color: #2D0933;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url(/dist/images/bg-dots.svg);
		filter: blur(6px);
		opacity: 0.25;
		z-index: -2;
	}

	&.theme-info {
		height: 760px;

		@include breakpoint(medium down) {
			height: auto;
		}
	}

	&.theme-show {
		background-position: bottom right;
		&:after {
			background-image: none;
			background-color: inherit;
			filter: none;
			opacity: 0.85;
		}
	}

	.video-container {
		height: 100%;
		width: 100%;
		z-index: -1;
		overflow: hidden;
		position: absolute;
		top: 0;

		@include breakpoint(medium down) {
			display: none;
		}

		&:after {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			content: '';
			background-image: linear-gradient(to bottom, rgba(0,0,0,0.9), rgba(255,0,0,0), rgba(0,0,0,0.9));
		}

		video {
			width: 1920px;
			margin: 0 auto;
			position: relative;
			top: -100px;
		}
	}
}

#vue-search {
	> .grid-container:first-of-type {

		width: 100%;
		margin-bottom: 0;

		@include breakpoint(medium down) {
			margin-bottom: 3rem;
		}

		@include breakpoint(small only) {
			margin-bottom: 2rem;
		}


		@include breakpoint(large up) {
			margin-bottom: 112px;
		}

		h1 {
			font-size: 52px;
			letter-spacing: 0;
			color: #fff;

			@include breakpoint(large down) {
				margin-bottom: 2rem;
				text-align: center;
				width: 100%;
			}

			@include breakpoint(medium down) {
				text-align: center;
				font-size: 28px;
				width: 100%;
				margin-top: 0;
				margin-bottom: 2rem;
			}

			@include breakpoint(small only) {
				margin-bottom: 1.25rem;
				margin-top: 0.5rem;
			}
		}

		.theme-frontpage & {
			flex-direction: column;
			margin-bottom: 110px;

			h1 {
				color: #fff;
				text-shadow: 2px 2px 10px rgba(#000, 0.6);

				@include breakpoint(small down) {
					font-size: 36px;
				}
			}

			> .grid-x {
				max-width: 1160px;
				margin: 0 auto;
				> .cell {
					display: block;
				}
			}
		}

		> .grid-x {
			width: 100%;

			> .cell {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
}

.cell.frontpage {
	h1, #search-wrapper2 {
		
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: 1200px;

		@include breakpoint(medium down) {
			margin-bottom: 2rem;
			max-width: 600px;
		}

		@include breakpoint(small only) {
			margin-bottom: 1.5rem;
		}
	}

	h1 {
		@include breakpoint(large up) {
			margin-bottom: 1rem;
			text-align: left;
		}
	}
}

#search-wrapper2 {
	color: #000;
	width: 100%;
	max-width: 1200px;
	user-select: none;
	
	@include breakpoint(medium down) {
		margin-bottom: 2rem;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}

	.search-button {
		height: 100%;
		position: relative;
		right:-1px;

		@include breakpoint(medium down) {
			height: 48px;
			margin: 28px 24px;
			border-radius: 40px;
			font-size: 20px;
			margin-top: 31px;
		}
	}
	.search-row {
		background: #fff;
		height: 96px;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06);
		z-index: 100;
		max-width: 1200px;
		width: 100%;

		@include breakpoint(medium down) {
			height: auto;
		}

		.cell,
		.grid-x {
			height: 100%;
		}
	}

	.search-show {
		display: block;
		width: 100%;
		border: none;
		box-shadow: 0 1px 0 0 rgba($black, 0.07);
		height: 60px;
		padding: 0 20px;
		font-size: 1rem;
		letter-spacing: 0;
		background: #fff;
		background-image: url(/dist/images/search-icon.svg);
		background-position: right 20px center;
		background-repeat: no-repeat;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: rgba($black, 0.4);
		}
	}

	.dropdown {
		position: relative;
		background: #FFFFFF;
		box-shadow: 0 0 27px 0 rgba(0,0,0,0.35);
		height: 0;
		overflow: hidden;
		margin-bottom: 40px;
		border-radius: 0 0 5px 5px;

		&.open {
			height: auto;
			max-height: 380px;
			overflow-y: auto;
			z-index: 10;
			display: block;

			&.calendar {
				max-height: 480px;
			}

			@include breakpoint(medium down) {
				position: fixed;
				top: 75px;
				right: 0;
				left: 0;
				bottom: 0;
				z-index: 1000;
				margin-bottom: 0;
				border-radius: 0;
				box-shadow: none;
				max-height: initial;

				&.calendar {
					max-height: initial;
				}
			}

			&:before {
				content: '';
				position: absolute;
				background: #fff;
				top: 0; right: 0; left: 0;
				height: 10px;
				z-index: 100;
			}
		}

		ul {
			margin: 0;
			padding: 16px;
			list-style: none;

			li {
				display: block;
				padding: 12px;
				font-size: 13px;
				letter-spacing: 0;
				line-height: 17px;
				border-radius: 2px;
				background: transparent;
				transition: all 400ms $transition;
				margin-bottom: 2px;
				font-weight: bold;

				&.selected {
					background: #F6F1F5;
					color: $color-latter;

					span {
						color: $color-latter;
					}

					&:hover {
						background: #F6F1F5;
						color: $color-latter;
						cursor: default;
					}
				}

				&:hover {
					background: rgba(#F6F1F5, 0.75);
					cursor: pointer;
				}

				span {
					font-size: 11px;
					display: block;
					color: #666666;
					margin-bottom: -4px;
				}
			}
		}
	}

	.option-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		border-bottom: 1px solid transparent;
		padding: 10px 0;
		z-index: 100;
		border: 1px solid transparent;

		&:hover {
			cursor: pointer;
		}

		@include breakpoint(medium down) {

			&:first-child {
				margin-top: 3px;
			}

			&:after {
				content: '';
				position: absolute;
				width: calc(100% - 3.5rem);
				height: 3px;
				bottom: -2px;
				left: 1.75rem;
				background-image: url(/dist/images/border.svg);
				background-position: center -1px;
				background-repeat: repeat-x;
			}

			min-height: 82px !important;
			&:last-of-type {
				border: none;
			}
		}

		&.open {
			border: 1px solid #EBE8E2;
			background: #FCFCFB;

			&:before {
				position: absolute;
				content: '';
				top: -1px; right: -1px; left: -1px;
				height: 3px;
				z-index: 10;
				background-image: linear-gradient(-94deg, #D1A22E 4%, #F1E187 33%, #F1E187 69%, #D1A22E 100%);
			}

			&:after {
				position: absolute;
				bottom: -8px; right:25px;
				content: '';
				background: #fff;
				height: 16px; width: 16px;
				transform: rotate(45deg);
				border-top: 1px solid #EBE8E2;
				border-left: 1px solid #EBE8E2;
			}

			@include breakpoint(medium down) {
				position: fixed;
				top: 0; right: 0; left: 0;
				z-index: 1010;
				height: 75px;
				border-bottom: 1px solid #EBE8E2 !important;
			}

			.icon {
				display: none !important;
			}

			.icon-close {
				display: flex !important;

				&:after {
					position: absolute;
					top: 0; right: 0; bottom: 0; left: 0;
					content: '';
				}
			}
		}
	}

	.option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1 0 auto;
		padding: 0 28px;
		max-width: 100%;
		position: relative;

		&:before,
		&:after {
			width: 1px;
			top: 0;
			bottom: 0;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;
			position: absolute;
		}

		@include breakpoint(large up) {
			&.borderLeft {
				&:before {
					content: '';
					left: -1px;
				}
			}

			&.borderRight {
				&:after {
					content: '';
					right: -1px;
				}
			}
		}

		

		label {
			opacity: 0.38;
			font-size: 12px;
			color: #000000;
			letter-spacing: 1.15px;
			font-weight: bold;
		}

		.label {
			flex-grow: 1;
			width: 85%;
		}

		h3 {
			margin: 0;
			font-size: 1rem;
			color: $black;
			letter-spacing: 0;
			font-weight: $global-weight-light;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@include breakpoint(medium up) {
				font-size: 18px;
			}
		}

		.icon,
		.icon-close {
			height: 32px;
			width: 32px;
			flex-shrink: 0;
			flex-grow: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			&.hidden {
				display: none;
			}
		}

		.icon-close {
			position: relative;
			right: -10px;
		}

		.icon-close {
			display: none;
		}
	}
}

.option-wrapper:first-of-type {

	.option {
		.icon {
			position: relative;
			right: -5px;
		}
	}
	
}

.calendar .info {
	padding: 28px;
	padding-top: 0;
	font-size: 16px;
	display: none;
}

.calendar-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 28px;
	padding-top: 0;

	.clearCalendar {
		padding: 0;
		color: $black;

		&:hover {
			cursor: pointer;
		}
	}

	.confirm {
		padding: 1px;
		border-radius: 30px;
		user-select: none;
		font-weight: normal;

		&:hover {
			cursor: pointer;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			width: 110px;
			height: 47px;
			border-radius: 30px;
			font-weight: $global-weight-bold;
			font-size: rem-calc(14px);
			position: relative;
		}
	}
}

#calendar {
	padding: 28px;
	padding-top: 16px;

	.datepicker--content {
		padding: 0;
	}

	#air-datepicker,
	.datepicker--buttons {
		display: none;
	}

	.datepicker {
		width: 100%;
		border: none;
		color: $black;
		font-family: $body-font-family;
		font-weight: $global-weight-light;
	}

	.datepicker--nav {
		border: none;
	}

	.datepicker--nav-title {
		font-weight: $global-weight-bold;
		color: $black;

		i {
			color: inherit;
		}
	}

	.datepicker--day-name {
		color: #A3A3A3;
		font-weight: $global-weight-bold;
		font-size: rem-calc(10px);
		margin-bottom: 2px;

	}

	.datepicker--cells-days {
		border-left: 1px solid #EBE8E2;
		border-top: 1px solid #EBE8E2;
	}

	.datepicker--nav-action path {
		stroke: $black;
	}

	.datepicker--cell-day {
		border-radius: 0;
		border-right: 1px solid #EBE8E2;
		border-bottom: 1px solid #EBE8E2;
		font-size: 13px;
		font-family: $body-font-family;
		font-weight: 400;
		height: 34px;

		&:after, &:before {
			pointer-events: none !important;
		}

		&.-range-to-,
		&.-range-from- {
			border: none;
			border-right: 1px solid #EBE8E2;
			border-bottom: 1px solid #EBE8E2;
		}

		&.-other-month- {
			color: rgba($black, 0.2);
			font-weight: 400;
		}

		&.-current- {
			color: #EF674E;
			font-weight: $global-weight-bold;
		}

		&.-in-range- {
			background: transparent;
			z-index: 1;
			color: $color-latter;

			&:after {
				opacity: 1;
				top: 7px; right: 0px; bottom: 7px; left: 0px;
				width: 100%;
			}
		}

		&.-focus-:not(.-disabled-) {
			background: transparent;
			color: $color-latter;
			z-index: 1;

			&:before {
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}

			&.-range-to-:after {
				left: auto;
				right: 50%;
			}
		}

		&.-disabled- {
			&.-focus- {
				background-color: $white;
			}
		}

		&:before {
			content: '';
			position: absolute;
			height: 28px;
			background: #EDE3EB;
			width: 28px;
			border-radius: 100%;
			z-index: -1;
			opacity: 0;
		}

		&:after {
			content: '';
			position: absolute;
			top: 7px;
			right: 0px;
			bottom: 7px;
			left: 50%;
			width: 50%;
			background: rgba($color-latter, 0.06);
			z-index: -2;
			opacity: 0;
		}

		&.-selected- {
			background: transparent;
			color: $color-latter;
			position: relative;
			z-index: 1;
			font-weight: $global-weight-bold;

			&:before {
				opacity: 1;
			}

			&:after {
				opacity: 1;
			}

			&.-range-to-:after {
				left: auto;
				right: 50%;
			}

		}
	}

	.datepicker--cell.-selected-, .datepicker--cell.-selected-.-current- {
		background: transparent;
	}

	.datepicker--cell.-current- {
		color: #EF674E;
	}
}

.infobox {
	background-color: #fbfbfa;
	display: none;
	padding: 3rem 1.5rem 0rem 1.5rem !important;
	font-size: 16px;
	color: $black;
	text-align: center;

	@include breakpoint(small only) {
		margin-bottom: -2rem;
	}

	@include breakpoint(medium up) {
		font-size: 22px;
		padding: 3rem 5rem 0rem 5rem !important;
	}

	@include breakpoint(xlarge up) {
		font-size: 24px;
		min-height: 48px;
		padding: 6rem 0 0rem 0 !important;
	}

	&.visible {
		display: flex;
		align-items: center;
		justify-content: center;

		&.bg-darker {
			border-bottom: none;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				right: 0; left: 0;
				height: 2px;
				background: #F8F7F5;
			}
		}
	}

	.loading {
		width: 100px;

		svg {
			width: 100%;
			height: auto;
		}
	}
}

.search-results {
	background-color: #fbfbfa;
	padding-top: 110px;
	padding-bottom: 13px;
	display: none;

	&.visible {
		display: block;
	}

	.location {
		padding: 0 15px;
	}

	.day {
		padding-bottom: 60px;

		@include breakpoint(small only) {
			padding-bottom: 2rem;
		}

		@include breakpoint(small only) {
			padding-bottom: 1.5rem;
		}
	}

	.month {
		margin-bottom: 60px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			left: 0;
			right: 0;
			top: bottom;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;
		}
		
		@include breakpoint(small only) {
			margin-bottom: 2rem;
		}

		@include breakpoint(small only) {
			margin-bottom: 1.5rem;
		}
	}

	.remove-border-top {
		position: relative;

		&:after {
			content: '';
			height: 1px;
			position: absolute;
			top: 0; right: 0; left: 0;
			background: #fbfbfa;
		}
	}

	.date {
		color: #9D895E;
		font-family: $heading-font-family;
		font-weight: $global-weight-regular;
		text-align: center;
		display: inline-block;
		width: 115px;

		@include breakpoint(medium down) {
			margin-bottom: 1rem;
			width: auto;
		}

		@include breakpoint(small only) {
			margin-bottom: 0.25rem;
		}

		span {
			display: block;
			line-height: 1;
			text-transform: uppercase;

			&:first-of-type {
				font-size: 78px;
				letter-spacing: 0;
				font-family: $heading-font-family;
				font-weight: $global-weight-regular;

				@include breakpoint(small only) {
					font-size: 48px;
				}
			}

			&:nth-of-type(2) {
				font-size: 16px;
				letter-spacing: 1.84px;
				font-family: $heading-font-family;
				font-weight: $global-weight-bold;
				color: #000;

				@include breakpoint(small only) {
					font-size: 13px;
				}
			}

			&:last-of-type {
				padding-top: 5px;
				font-size: 12px;
				letter-spacing: 1.84px;
				font-family: $heading-font-family;
				font-weight: $global-weight-bold;
				color:rgba(#000, 0.6);

				@include breakpoint(small only) {
					font-size: 11px;
				}
			}
		}
	}


	@include breakpoint(large down) {
		.cell.location {
			display: none;
		}
	}
	

	@include breakpoint(small only) {
		

		.availability {
			margin-left: 0.25rem;

			span {
				display: none;
			}
		}

		.button-wrapper {
			display: none;
		}

		.cell.small-shrink {
			flex-grow: 0;
			flex-shrink: 1;
			width: auto;
		}

		.cell.small-auto {
			flex-grow: 1;
			flex-shrink: 0;
			width: auto;
		}
	}
}


.performance-line.loop-1 {
	margin-top: -12px;
}

.bg-light.featured {
	.performance-line {
		.button-wrapper {
			a {
				background-color: $lighter-background-color;
			}

			@include breakpoint(small only) {
				display: none;
			}
		}
	}
}

.search-results.bg-light {
	.performance-line {
		.button-wrapper {
			a {
				background-color: $lighter-background-color;
			}
		}
	}
}


.performance-line {
	position: relative;
	padding: 12px 0;
	font-family: $heading-font-family;
	font-weight: $global-weight-regular;
	color: #000;

	a {
		color: inherit;

		&:hover {
			h2 {
				color: lighten($color-latter,15%);
			}
		}
	}

	h2, h3, h4 {
		margin: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	h2 {
		font-size: 22px;
		font-weight: $global-weight-medium;
		transition: color 300ms $transition;

		@include breakpoint(small only) {
			font-size: rem-calc(16px);
		}
	}

	h3 {
		font-size: 16px;
		opacity: 0.7;
		font-weight: $global-weight-regular;

		@include breakpoint(small only) {
			font-size: rem-calc(13px);
		}
	}

	h4 {
		font-size: 14px;
	}

	&:after {
		content: '';
		position: absolute;
		height: 1px;
		left: 0; right: 0; top: 0;
		background-image: url(/dist/images/border.svg);
		background-position: bottom left;
	}

	.cell {
		display: flex;
		align-items: center;

		&.space-between {
			justify-content: space-between;
		}
	}

	.image {
		margin-right: 24px;

		@include breakpoint(small only) {
			margin-right: 10px;
		}

		img {
			width: 76px;
			max-width: 76px;

			@include breakpoint(small only) {
				width: 65px;
				max-width: 65px;
			}
		}
	}

	.date-time {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		color: #000000;
		letter-spacing: 0.88px;
		text-align: right;
		text-transform: uppercase;
		font-weight: $global-weight-regular;

		@include breakpoint(small only) {
			font-size: rem-calc(12px);			
		}

		span:first-child {
			font-weight: $global-weight-medium;
		}
	}

	.performance-time {
		@include breakpoint(small only) {
			font-size: rem-calc(12px);
		}
	}

}

#shows {
	background-color: #fbfbfa;
	padding-top: 110px;
	padding-bottom: 78px;

	&.hidden {
		display: none;
	}
}

.show-card-large {
	display: block;
	background: #fff;
	box-shadow: 0px 2px 4px rgba(#000, 0.05);
	padding: 8px;
	color: #000;
	position: relative;

	@include breakpoint(medium down) {
		margin-bottom: 4rem;
	}

	@include breakpoint(small only) {
		margin-bottom: 3rem;
	}

	img {
		width: 100%;
		height: auto;
	}

	.button-wrapper {
		a {
			background: #fff;
		}
	}

	.top {
		font-size: 18px;
		color: $black;
		letter-spacing: 0;
		line-height: 28px;
		padding: calc(1.5rem - 0.5rem);

		@include breakpoint(medium up) {
			padding: calc(2rem - 0.5rem);
		}

		@include breakpoint(xlarge up) {
			padding: calc(3rem - 0.5rem);
		}

		h2 {
			font-size: 24px;
			letter-spacing: 0;
			line-height: 32px;
			margin-bottom: 20px;
			margin-bottom: 12px;

			@include breakpoint(medium up) {
				font-size: 28px;
				margin-bottom: 16px;
				line-height: 36px;
			}

			@include breakpoint(xlarge up) {
				font-size: 32px;
				margin-bottom: 20px;
				line-height: 39px;
			}
		}

		p {
			@include breakpoint(large down) {
				font-size: 1rem;
			}

			@include breakpoint(small only) {
				font-size: rem-calc(14px);
			}
		}
	}

	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: calc(1.5rem - 0.5rem);
		padding-top: 1rem;

		@include breakpoint(medium up) {
			padding: calc(2rem - 0.5rem);
			padding-top: 1rem;
		}

		@include breakpoint(xlarge up) {
			padding: calc(3rem - 0.5rem);
			padding-top: 1rem;
		}



		&:after {
			content: '';
			position: absolute;
			height: 1px;
			top: 0;
			right: 1rem;
			left: 1rem;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;

			@include breakpoint(medium up) {
				right: 1rem;
				left: 1rem;
			}

			@include breakpoint(large up) {
				right: 1.5rem;
				left: 1.5rem;
			}

			@include breakpoint(xlarge up) {
				right: 2.5rem;
				left: 2.5rem;
			}
		}
	}

	.dates {
		background-image: url(/dist/images/stripes.png);
		background-size: 10px;
		width: 128px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 3px;
		border-radius: 30px;

		> span {
			background: #64134D;
			border-radius: 100px;
			height: 2px;
			width: 12px !important;
			margin-left: 10px;
		}

		.date {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			background: #fff;
			width: 42px;
			height: 42px;
			border-radius: 100%;
			z-index: 1;
			text-transform: uppercase;
			color: $color-latter;

			&:first-of-type {
				background: transparent;

				&:before {
					content: '';
					width: 52px;
					height: 52px;
					background: #fff;
					transform: rotate(45deg);
					position: absolute;
					z-index: -1;
					left: -10px;
				}
			}

			span:last-of-type {
				font-family: $heading-font-family;
				font-weight: $global-weight-regular;
				font-size: 11px;
				letter-spacing: 0;
				line-height: 1;
			}

			span:first-of-type {
				font-family: $heading-font-family;
				font-weight: $global-weight-bold;
				font-size: 15px;
				letter-spacing: 0;
				line-height: 1
			}
		}
	}
}

.show-card {
	margin-bottom: 32px;
	background: #fff;
	box-shadow: 0px 2px 4px rgba(#000, 0.05);
	padding: 8px;
	color: #000;
	display: flex;
	flex-direction: column;

	> img {
		width: 100%;
		height: auto;
	}

	.image {
		flex: 0 0 auto;
	}

	&:hover {
		cursor: pointer;
	}

	.content {
		padding: 8px;
		padding-top: 22px;

		h2 {
			font-size: 22px;
			letter-spacing: 0;
			text-align: center;
			margin: 0;
		}

		h3 {
			opacity: 0.7;
			font-size: 16px;
			letter-spacing: 0;
			text-align: center;
			margin: 0;
		}
	}

	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px;
		padding-top: 16px;
		position: relative;
		margin-top: 2rem;

		@include breakpoint(small only) {
			margin-top: 1.25rem;
		}

		> span {
			width: auto;
			font-family: $heading-font-family;
			font-weight: $global-weight-regular;
		}
		&:after {
			content: '';
			position: absolute;
			height: 1px;
			left: 8px; right: 8px; top: 0;
			background-image: url(/dist/images/border.svg);
			background-position: bottom left;
		}

		.button-wrapper {

			a, div {
				background: #fff;				
			}

			span {
				font-weight: $global-weight-bold;
				padding: 0 14px;
				width: 100%;
			}
		}

		span {
			display: flex;
			align-items: center;
			padding-left: 12px;
			padding-right: 12px;


			img {
				margin-right: 10px;
			}
		}
	}

	.dates {
		background-image: url(/dist/images/stripes.png);
		background-size: 10px;
		width: 128px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 3px;
		border-radius: 30px;
		margin-bottom: 14px;

		> span {
			background: #64134D;
			border-radius: 100px;
			height: 2px;
			width: 12px;
			margin-left: 10px;
		}

		.date {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			background: #fff;
			width: 42px;
			height: 42px;
			border-radius: 100%;
			z-index: 1;
			text-transform: uppercase;
			color: $color-latter;

			&:first-of-type {
				background: transparent;

				&:before {
					content: '';
					width: 52px;
					height: 52px;
					background: #fff;
					transform: rotate(45deg);
					position: absolute;
					z-index: -1;
					left: -10px;
				}
			}

			span:last-of-type {
				font-family: $heading-font-family;
				font-weight: $global-weight-regular;
				font-size: 11px;
				letter-spacing: 0;
				line-height: 1;
			}

			span:first-of-type {
				font-family: $heading-font-family;
				font-weight: $global-weight-bold;
				font-size: 15px;
				letter-spacing: 0;
				line-height: 1
			}
		}
	}
}

#show-tabs {
	color: #fff;
	z-index: 1;
	position: relative;
	background-color: $header-background-color;
	//background-image: radial-gradient(circle at top center, #691342,#2A0B37);
	padding: 3rem 0;
	overflow: hidden;

	@include breakpoint(medium up) {
		padding: 4rem 0;
	}

	@include breakpoint(large up) {
		padding: 5rem 0;
	}

	@include breakpoint(xlarge up) {
		padding: 7rem 0;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		background-image: url(/dist/images/bg-dots.svg);
		filter: blur(6px);
		opacity: 0.2;
		z-index: -1;
	}

	.tabs {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 35px;
		height: 43px;
		padding: 0px;

		@include breakpoint(small only) {
			flex-wrap: wrap;
			height: auto;
			margin-bottom: 1.5rem;
		}

		.flex-1 {
			flex: 1 0 auto;
			background-image: url(/dist/images/border-light.svg);
			background-repeat: repeat-x;
			background-position: center -1px;
			height: 5px;

			@include breakpoint(small only) {
				display: none;
			}
		}

		.tab {
			user-select: none;
			padding: 0 10px;
			font-family: $heading-font-family;
			font-weight: $global-weight-regular;
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: 1.84px;
			line-height: 1;
			color: rgba($white, 0.7);
			transition: color 300ms $transition;

			@include breakpoint(small only) {
				width: 100%;
				margin-bottom: 1rem;
				position: relative;
			}

			&:hover {
				cursor: pointer;
				color: $white;
			}

			&.active {
				font-family: $heading-font-family;
				font-weight: $global-weight-bold;
				color: #F4E68D;
			}
		}
	}

	.slider {
		height: 0px;
		opacity: 0;

		@include breakpoint(small only) {
			width: 90%;
		}

		&.active {
			height: auto;
			opacity: 1;
		}
	}

	.item {
		padding: 0;

		&:focus {
			outline: none;
		}
	}
}

.featured {
	padding: 110px 0;

	.performance-line {

		&.loop-1 {
			&:after {
				display: none;
			}
		}

		.button-wrapper {
			margin-left: 20px;
		}

		.content {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
			margin-right: 20px;
		}
	}
}

#vue-search {
	.bg-darker {
		.remove-border-top:after {
			background: #F8F7F5;
		}
	}
}

#comedians.slick-slider {

	@include breakpoint(small only) {
		width: 90%;
	}
}

.availability {
	display: flex;
	align-items: center;

	img {
		margin-right: 10px;
	}
}

.xxltable {
	display: flex;
	align-items: center;

	.trigger {
		display: inline-block;
		margin: 0 auto;
		color: #FFF;
		text-shadow: 0 1px 1px rgba(197, 150, 35, 0.68);
		padding: 1.5rem 3rem;
		font-size: 30px;
		font-weight: 500;

		&:hover {
			cursor: pointer;
		}
	}
}

.xxltable-window {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background: rgba(#000, 0.8);
	display: none;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
	}

	&.open {
		display: flex;
		z-index: 2000;

		@include breakpoint(medium down) {
			display: none;
			z-index: 0;
		}
	}

	.close {
		position: absolute;
		top: 40px;
		right: 40px;
		transform: scale(1.6);
	}

	iframe {
		width: 100%;
		max-width: 500px;
		height: 100%;
		max-height: 500px;
	}

	span {
		display: inline-block;
	}
}